import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities";
import { getWebsiteURL, isDev } from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import { MenuProps } from "antd/es/menu";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app";
import { fetchProjectMatches } from "../../features/api/slices/projects-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";

type ProjectActionsProps = {
  project: Project;
};

const ProjectActions = ({ project }: ProjectActionsProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;

  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleDeleteProject = () => {
    if (!!project.activated_at) {
      Modal.error({
        title: t("project|actions.deleteError"),
      });
    } else {
      Modal.confirm({
        title: t("project|actions.delete"),
        content: t("project|actions.deleteConfirm"),
        okText: t("button.confirm"),
        cancelText: t("button.cancel"),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: "24px",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: "24px",
          },
        },
        onOk() {
          return new Promise((resolve, reject) => {
            api
              .deleteProject(project.project_id || "")
              .then(() => {
                notification.success({
                  message: "successDelete",
                  placement: "topLeft",
                  duration: 0,
                });
                navigate("/projects");
                resolve("Suppression");
              })
              .catch((e: Error) => reject(e));
          }).catch(() => console.error("Oops errors!"));
        },
      });
    }
  };

  const handleResetMatches = async () => {
    await api.resetProjectMatches(project.project_id);
    await api.fetchProjectPurchasedMatches(project.project_id);

    dispatch(fetchProjectMatches({ api, projectId: project.project_id }));
  };

  const handleResetPurchases = async () => {
    await api.resetProjectPurchases(project.project_id);
    await api.fetchProjectPurchasedMatches(project.project_id);

    dispatch(fetchProjectMatches({ api, projectId: project.project_id }));
  };

  const handleResetRaiseHands = async () => {
    await api.resetProjectRaiseHands(project.project_id);
    await api.fetchProjectPurchasedMatches(project.project_id);

    dispatch(fetchProjectMatches({ api, projectId: project.project_id }));
  };

  const devActionMenuItems: MenuProps["items"] = [
    {
      label: "Public Project Update",
      onClick: () =>
        window.open(
          getWebsiteURL() +
            "/project-status-update?token=" +
            project.status_update_token,
          "_blank"
        ),
      key: 1,
      icon: <NewWindow width={16} style={{ color: theme.colors.purple_1 }} />,
    },
    {
      label: t("project|actions.delete"),
      onClick: toggleDeleteProject,
      key: 2,
    },
    {
      label: "Reset Matches",
      onClick: handleResetMatches,
      key: 3,
    },
    {
      label: "Reset Purchases",
      onClick: handleResetPurchases,
      key: 4,
    },
    {
      label: "Reset Raise Hands",
      onClick: handleResetRaiseHands,
      key: 5,
    },
  ];
  const actionMenuItems: MenuProps["items"] = [];
  actionMenuItems?.push({
    label: "Public",
    onClick: () =>
      window.open(
        getWebsiteURL() +
          "/project-status-update?token=" +
          project.status_update_token,
        "_blank"
      ),
    key: 1,
    icon: <NewWindow width={16} style={{ color: theme.colors.purple_1 }} />,
  });
  if (
    project.status === ProjectStatus.PendingApproval ||
    project.status === ProjectStatus.Canceled
  ) {
    actionMenuItems?.push({
      label: t("project|actions.delete"),
      onClick: toggleDeleteProject,
      key: 1,
    });
  }

  return (
    <>
      {isDev ? (
        <Dropdown menu={{ items: devActionMenuItems }}>
          <Button type="link">
            <Typography elementTheme="subtitle1">
              <EllipsisOutlined />
            </Typography>
          </Button>
        </Dropdown>
      ) : (
        <Dropdown menu={{ items: actionMenuItems }}>
          <Button type="link">
            <Typography elementTheme="subtitle1">
              <EllipsisOutlined />
            </Typography>
          </Button>
        </Dropdown>
      )}
    </>
  );
};

export default ProjectActions;

