import {
  ApiError,
  ApiErrorInitialState,
  GrantProject
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

const grantProjectsAdapter = createEntityAdapter({
  selectId: (model: GrantProject) => model.grant_project_id,
});

export interface GrantProjectState {
  status: "idle" | "pending";
  error: ApiError;
  grantProjects: EntityState<GrantProject, string>;
  bestGrantProjects: EntityState<GrantProject, string>;
}

const initialState: GrantProjectState = {
  status: "idle",
  error: ApiErrorInitialState,
  grantProjects: grantProjectsAdapter.getInitialState(),
  bestGrantProjects: grantProjectsAdapter.getInitialState(),
};

export const fetchGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: AdminApiClient; grantId: string },
  { rejectValue: ApiError }
>(
  "admin/fetchGrantProjects",
  async (
    { api, grantId }: { api: AdminApiClient; grantId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantProjectsByGrantId(grantId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const createGrantProject = createAsyncThunk<
  GrantProject,
  { api: AdminApiClient; grantProject: GrantProject },
  { rejectValue: ApiError }
>(
  "admin/createGrantProject",
  async (
    { api, grantProject }: { api: AdminApiClient; grantProject: GrantProject },
    { rejectWithValue }
  ) => {
    try {
      const createdGrantProject = await api.createGrantProject(grantProject);
      return createdGrantProject;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGrantProject = createAsyncThunk<
  GrantProject,
  { api: AdminApiClient; grantProject: GrantProject },
  { rejectValue: ApiError }
>(
  "admin/updateGrantProject",
  async (
    { api, grantProject }: { api: AdminApiClient; grantProject: GrantProject },
    { rejectWithValue }
  ) => {
    try {
      const updatedGrantProject = await api.updateGrantProject(grantProject);
      return updatedGrantProject;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: AdminApiClient; grantId: string; count: number },
  { rejectValue: ApiError }
>(
  "admin/generateGrantProjects",
  async (
    {
      api,
      grantId,
      count,
    }: { api: AdminApiClient; grantId: string; count: number },
    { rejectWithValue }
  ) => {
    try {
      return await api.generateGrantProjects(grantId, count);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGrantProject = createAsyncThunk<
  string,
  { api: AdminApiClient; grantProjectId: string },
  { rejectValue: ApiError }
>(
  "admin/deleteGrantProject",
  async (
    { api, grantProjectId }: { api: AdminApiClient; grantProjectId: string },
    { rejectWithValue }
  ) => {
    try {
      await api.deleteGrantProject(grantProjectId);
      return grantProjectId;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAllGrantProjects = createAsyncThunk<
  string[],
  { api: AdminApiClient; grantProjectIds: string[] },
  { rejectValue: ApiError }
>(
  "admin/deleteGrantProjects",
  async (
    {
      api,
      grantProjectIds,
    }: { api: AdminApiClient; grantProjectIds: string[] },
    { rejectWithValue }
  ) => {
    try {
      await api.deleteGrantProjects(grantProjectIds);
      return grantProjectIds;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBestGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: AdminApiClient; locale: string },
  { rejectValue: ApiError }
>(
  "grantProjects/fetchBestGrantProjects",
  async (
    { api, locale }: { api: AdminApiClient; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchBestGrantProjects(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantProjectsSlice = createSlice({
  name: "grantProjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.upsertMany(state.grantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(fetchGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(generateGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(generateGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.upsertMany(state.grantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(generateGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(createGrantProject.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(createGrantProject.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.upsertOne(state.grantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(createGrantProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateGrantProject.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(updateGrantProject.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.updateOne(state.grantProjects, {
        id: payload.grant_project_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(updateGrantProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteGrantProject.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(deleteGrantProject.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.removeOne(state.grantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(deleteGrantProject.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteAllGrantProjects.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(deleteAllGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.removeMany(state.grantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(deleteAllGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchBestGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchBestGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.setAll(state.bestGrantProjects, payload ?? []);
      state.status = "idle";
    });
    builder.addCase(fetchBestGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllGrantProjects,
  selectById: selectGrantProjectById,
} = grantProjectsAdapter.getSelectors(
  (state: RootState) => state.grantProjects.grantProjects
);

export const {
  selectAll: selectBestGrantProjects,
} = grantProjectsAdapter.getSelectors(
  (state: RootState) => state.grantProjects.bestGrantProjects
);
export const selectBestGrantProjectsLoading = (state: RootState) =>
  state.grantProjects.status === "pending";

export const selectError = (state: RootState) =>
  state.grantProjects.error.error_code;

export const selectIsLoading = (state: RootState) =>
  state.grantProjects.status === "pending";

export const grantProjectsReducer = grantProjectsSlice.reducer;

