import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { ProjectDescriptionTemplate } from "@hellodarwin/core/lib/features/entities";
import Card from "antd/es/card";
import Form from "antd/es/form";
import message from "antd/es/message";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import { useAdminApi } from "../../features/api/use-admin-api";
import { quillModules } from "../text-editor";

type ProjectDescriptionTemplateFormProps = {
  projectDescriptionTemplate?: ProjectDescriptionTemplate;
  language: string;
};

export type ProjectDescriptionTemplateFormValues = {
  content: string;
  locale: string;
  project_description_type: string;
};

const ProjectDescriptionTemplateForm = ({
  projectDescriptionTemplate,
  language,
}: ProjectDescriptionTemplateFormProps) => {
  const api = useAdminApi();

  const [form] = Form.useForm<ProjectDescriptionTemplateFormValues>();

  const save = async () => {
    try {
      const projectDescriptionTemplateFormValues = form.getFieldsValue()!;

      if (!projectDescriptionTemplateFormValues) return;

      projectDescriptionTemplateFormValues.locale = language;

      const updatedProjectDescriptionTemplate: ProjectDescriptionTemplate = {
        ...projectDescriptionTemplate,
        ...projectDescriptionTemplateFormValues,
      };

      await api.updateProjectDescriptionTemplate(
        updatedProjectDescriptionTemplate
      );

      message.success("Template added!");
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
      return;
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if (isMounted) {
          if (projectDescriptionTemplate) {
            form.setFieldsValue(projectDescriptionTemplate);
          }
        }
      } catch (e: any) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [form, projectDescriptionTemplate]);

  const initialValues: ProjectDescriptionTemplateFormValues = {
    content: projectDescriptionTemplate?.content ?? "",
    locale: projectDescriptionTemplate?.locale ?? "",
    project_description_type:
      projectDescriptionTemplate?.project_description_type ?? "",
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      name="projectDescriptionTemplate"
      size="small"
    >
      <Card>
        <Div flex="column" gap={8}>
          <Form.Item
            label="Description"
            name="content"
            tooltip="This information will show in all applications. The description type are as followed : 
            Contact Description, Project Description, Context, Goals, Details, Service Provider Requirement, Budget, Contact, Other"
            rules={[
              () => ({
                validator(_, value) {
                  if (value === "" || value === "<p><br></p>") {
                    return Promise.reject(
                      new Error("Please add a description")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <ReactQuill
              className="text-editor"
              theme="snow"
              modules={quillModules}
              preserveWhitespace={true}
            />
          </Form.Item>
        </Div>
        <Div>
          <Button size="small" onClick={save}>
            Save
          </Button>
        </Div>
      </Card>
    </Form>
  );
};

export default ProjectDescriptionTemplateForm;

