import { IconProps } from "../features/entities/general";

const Marketing = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1238_2058)">
          <path
            d="M13.3768 22.4535C12.2896 22.3569 11.1866 22.3086 10.0741 22.3086H8.88888C7.0029 22.3086 5.19416 21.5452 3.86057 20.1862C2.52698 18.8273 1.77777 16.9842 1.77777 15.0624C1.77777 13.1406 2.52698 11.2975 3.86057 9.93861C5.19416 8.57968 7.0029 7.81625 8.88888 7.81625H10.0741C11.1866 7.81625 12.2896 7.76794 13.3768 7.67133M13.3768 22.4535C13.7766 24.0026 14.2996 25.5001 14.9333 26.9349C15.3236 27.8205 15.0281 28.8833 14.2017 29.368L13.1635 29.9799C12.2927 30.4919 11.1723 30.1683 10.7504 29.2376C9.749 27.03 8.986 24.718 8.47486 22.3424M13.3768 22.4535C12.7557 20.0409 12.4424 17.5567 12.4444 15.0624C12.4444 12.5086 12.7684 10.032 13.3768 7.67133M13.3768 22.4535C18.2463 22.8788 22.9882 24.2652 27.3383 26.5355M13.3768 7.67133C18.2464 7.24609 22.9882 5.85967 27.3383 3.58931M27.3383 26.5355C27.1518 27.1474 26.9511 27.7497 26.7378 28.3471M27.3383 26.5355C28.1979 23.712 28.7356 20.797 28.9406 17.8482M27.3383 3.58931C27.153 2.98046 26.9527 2.37642 26.7378 1.77777M27.3383 3.58931C28.1979 6.41281 28.7356 9.3278 28.9406 12.2767M28.9406 12.2767C29.7229 12.9417 30.2222 13.9433 30.2222 15.0624C30.2222 16.1816 29.7229 17.1831 28.9406 17.8482M28.9406 12.2767C29.0703 14.1315 29.0703 15.9934 28.9406 17.8482"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1238_2058">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Marketing;
