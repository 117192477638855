import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GrantCard from "@hellodarwin/core/lib/components/grants/card";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/filter/types";
import FundingExplorerListPageLayout from "@hellodarwin/core/lib/components/grants/funding-explorer-list-page";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  Company,
  GrantResult,
  NewTag,
  TagType,
} from "@hellodarwin/core/lib/features/entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/src/components/forms/utils/company-infos";
import Close from "@hellodarwin/icons/dist/icons/Close";
import { Pagination } from "antd";
import Collapse from "antd/es/collapse/Collapse";
import Form from "antd/es/form";
import Select from "antd/es/select";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import CompaniesSearch from "../../components/companies/companies-search";
import {
  fetchBestGrantProjects,
  selectBestGrantProjects,
} from "../../features/api/slices/grant-projects-slice";
import {
  fetchChildTags,
  selectActivityOptions,
  selectActivityTypeOptions,
  selectEligibleExpenseOptions,
  selectSectorOptions,
} from "../../features/api/slices/grant-tags-slice";
import {
  fetchGrantFinancingType,
  fetchGrantService,
  fetchProvinces,
  selectGrantFinancingType,
  selectGrantService,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../../features/api/slices/tags-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import useDebounce from "../../hooks/use-debounce";
import theme from "../../theme";
const FundingExplorer = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const provinces = useAppSelector(selectProvinces);
  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const [textQuery, setTextQuery] = useState<string | null>(null);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [company, setCompany] = useState<Company | null>(null);
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectors(state, selectedIndustries || [])
  );
  const services = useAppSelector(selectGrantService);
  const bestProjects = useAppSelector(selectBestGrantProjects);

  const sectorOptions = useAppSelector(selectSectorOptions);
  const activityTypeOptions = useAppSelector(selectActivityTypeOptions);
  const activityOptions = useAppSelector(selectActivityOptions);
  const eligibleExpenseOptions = useAppSelector(selectEligibleExpenseOptions);
  const [selectedSectors, setSelectedSectors] = useState<NewTag[]>([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<NewTag[]>(
    []
  );
  const [selectedActivities, setSelectedActivities] = useState<NewTag[]>([]);
  const [selectedEligibleExpenses, setSelectedEligibleExpenses] = useState<
    NewTag[]
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 10;

  type PageState = {
    grants: any[];
    pagination: {
      page: number;
      size: number;
    };
  };

  const pageStateInitialValues: PageState = {
    grants: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);

  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  useEffect(() => {
    dispatch(fetchBestGrantProjects({ api: api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBestGrantProjects]);

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, false, false);
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantService({ api, locale: selectedLocale }));
    dispatch(
      fetchChildTags({
        api,
        parentIds: [],
        locale: selectedLocale,
        type: TagType.Sector,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  useEffect(() => {
    if (selectedSectors.length > 0) {
      const parentIds = selectedSectors.map((sector) => sector.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.ActivityType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectors, selectedLocale]);

  useEffect(() => {
    if (selectedActivityTypes.length > 0) {
      const parentIds = selectedActivityTypes.map((type) => type.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.Activity,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityTypes, selectedLocale]);

  useEffect(() => {
    if (selectedActivities.length > 0) {
      const parentIds = selectedActivities.map((activity) => activity.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.EligibleExpense,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivities, selectedLocale]);

  const applyFilter = async () => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, false);
  };

  const onPageChange = async (page: number, pageSize: number) => {
    fetch(page, pageSize, true, false);
  };

  const FILTER_DELAY_MS = 500;
  const debouncedApplyFilter = useDebounce(textQuery, FILTER_DELAY_MS);

  useEffect(() => {
    if (debouncedApplyFilter || debouncedApplyFilter === "") {
      fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedApplyFilter]);

  const onTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextQuery(e.target.value);
  };

  const fetch = (
    page: number,
    size: number,
    loading: boolean,
    isSearching: boolean
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });
        const filterValues = filterform.getFieldsValue()!;
        const response: GrantResult[] = await api.queryFundingExplorer(
          selectedLocale,
          page,
          size,
          filterValues.textQuery,
          undefined,
          filterValues.filterBy,
          filterValues.industry,
          filterValues.subindustry,
          filterValues.status,
          filterValues.financingType,
          filterValues.region,
          filterValues.closingDate,
          filterValues.service,
          selectedSectors.map((tag) => tag?.tag_id),
          selectedActivityTypes.map((tag) => tag?.tag_id),
          selectedActivities.map((tag) => tag?.tag_id),
          selectedEligibleExpenses.map((tag) => tag?.tag_id),
          selectedProjects,
          filterValues.annualRevenue,
          filterValues.companySize
        );

        const newPageState = {
          grants: response?.map((grant, index) => ({
            key: index,
            ...grant,
          })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    options: { [key: string]: NewTag }
  ) => {
    const newTags = values.map((value) => options[value]);
    setSelected(newTags);
  };

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSectors,
    selectedActivityTypes,
    selectedActivities,
    selectedEligibleExpenses,
  ]);

  const onCompanySelect = (company: Company | null) => {
    if (company) {
      setCompany(company);
      if (company.industry_sector) {
        filterform.setFieldValue("industry", company.industry_sector);
        setSelectedIndustries(company.industry_sector!);
      }
      if (company.industry_subsector) {
        filterform.setFieldValue("subindustry", company.industry_subsector);
      }
      if (company.province) {
        filterform.setFieldValue("region", company.province);
      }
      if (company.annual_revenue) {
        filterform.setFieldValue("annualRevenue", company.annual_revenue);
      }
      if (company.size) {
        filterform.setFieldValue("companySize", company.size);
      }
      fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, false);
    }
  };

  const ShowCompanyInfo = () => (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      gap={24}
      backgroundColor={theme.colors.white_2}
      style={{ padding: 24 }}
    >
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="subtitle1">Company Id</Typography>
        <Link target="_blank" to={`/companies/${company?.company_id}`}>
          {getShortId(company!.company_id)}
        </Link>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="subtitle1">Name</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.name}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Industry</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.industry_sector
            ? company?.industry_sector
            : "No industry specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Subindustry</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.industry_subsector
            ? company?.industry_subsector
            : "No subindustry specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Company Size</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.size ? company?.size : "No size specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Region</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.province ? company?.province : "No region specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Annual Revenue</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.annual_revenue
            ? company?.annual_revenue
            : "No annual revenue specified"}
        </Typography>
      </Div>
      <Div
        alignSelf="flex-end"
        onClick={removeCompany}
        style={{ cursor: "pointer", width: 16 }}
      >
        <Close width={16} />
      </Div>
    </Div>
  );

  const removeCompany = () => {
    filterform.resetFields();
    setCompany(null);
  };

  const resetFilter = () => {
    filterform.resetFields();
    setSelectedIndustries([]);
    setSelectedSectors([]);
    setSelectedActivityTypes([]);
    setSelectedActivities([]);
    setSelectedEligibleExpenses([]);
    setSelectedProjects([]);
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, true, false);
  };

  const TagsCollapse = () => {
    return (
      <Collapse ghost style={{ width: "100%" }}>
        <Collapse.Panel header={"Tag Filters"} key="1">
          <Div flex="row" gap={8}>
            {[
              TagType.Sector,
              TagType.ActivityType,
              TagType.Activity,
              TagType.EligibleExpense,
            ].map((type, idx) => (
              <Div key={idx}>
                <Typography elementTheme="body3">
                  {type
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
                <Select
                  mode="multiple"
                  placeholder={`Select ${type
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}(s)`}
                  style={{ width: "100%" }}
                  value={
                    type === TagType.Sector
                      ? selectedSectors.map((tag) => tag.tag_id)
                      : type === TagType.ActivityType
                        ? selectedActivityTypes.map((tag) => tag.tag_id)
                        : type === TagType.Activity
                          ? selectedActivities.map((tag) => tag.tag_id)
                          : selectedEligibleExpenses.map((tag) => tag.tag_id)
                  }
                  onChange={(values) =>
                    handleTagChange(
                      values,
                      type === TagType.Sector
                        ? setSelectedSectors
                        : type === TagType.ActivityType
                          ? setSelectedActivityTypes
                          : type === TagType.Activity
                            ? setSelectedActivities
                            : setSelectedEligibleExpenses,
                      type === TagType.Sector
                        ? sectorOptions.entities
                        : type === TagType.ActivityType
                          ? activityTypeOptions.entities
                          : type === TagType.Activity
                            ? activityOptions.entities
                            : eligibleExpenseOptions.entities
                    )
                  }
                  options={Object.values(
                    type === TagType.Sector
                      ? sectorOptions.entities
                      : type === TagType.ActivityType
                        ? activityTypeOptions.entities
                        : type === TagType.Activity
                          ? activityOptions.entities
                          : eligibleExpenseOptions.entities
                  )?.map((option) => ({
                    label: option.content,
                    value: option.tag_id,
                  }))}
                />
              </Div>
            ))}
          </Div>
        </Collapse.Panel>
      </Collapse>
    );
  };

  return (
    <PageLayout
      app="admin"
      title={"Funding Explorer"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Funding Explorer",
        },
      ]}
      actions={
        <Div flex="row" fitContent>
          <Typography style={{ paddingRight: "8px" }} nowrap>
            {t(`grants|results`)}
          </Typography>
          <Typography bold nowrap>
            {t(`grants|nbGrants`, {
              nb:
                pageState.grants !== undefined && pageState.grants.length > 0
                  ? pageState.grants[0]?.total_count
                  : 0,
            })}
          </Typography>
        </Div>
      }
    >
      <CompaniesSearch handleSubmit={onCompanySelect} />
      {company ? <ShowCompanyInfo /> : null}
      <FundingExplorerListPageLayout
        filterform={filterform}
        onTextQueryChange={onTextQueryChange}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        companySize={companySize}
        annualRevenue={annualRevenue}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        selectedIndustries={selectedIndustries}
        setSelectedIndustries={setSelectedIndustries}
        TagsCollapse={TagsCollapse}
        bestProjects={bestProjects}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      />
      <Div flex="row">
        <Typography style={{ paddingRight: "8px" }}>
          {t(`grants|results`)}
        </Typography>
        <Typography bold>
          {t(`grants|nbGrants`, {
            nb:
              pageState.grants !== undefined && pageState.grants.length > 0
                ? pageState.grants[0]?.total_count
                : 0,
          })}
        </Typography>
      </Div>

      {apiState.isLoading ? (
        <Loading />
      ) : pageState.grants !== undefined && pageState.grants.length > 0 ? (
        pageState.grants.map((grant, index) => (
          <GrantCard key={index} {...grant} />
        ))
      ) : null}
      <Div flex="row" justify="center">
        <Pagination
          total={
            pageState.grants !== undefined && pageState.grants.length > 0
              ? pageState.grants[0]?.total_count
              : 0
          }
          current={pageState.pagination.page}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
      </Div>
    </PageLayout>
  );
};

export default FundingExplorer;

