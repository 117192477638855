import UserMenu from "@hellodarwin/core/lib/components/layout/header/user-menu";
import { NavigationLink } from "@hellodarwin/core/lib/features/entities";
import { useLogin } from "@hellodarwin/core/lib/features/providers/login-provider";
import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Settings from "@hellodarwin/icons/dist/icons/Settings";
import Spin from "antd/es/spin";
import { useNavigate } from "react-router-dom";

const HeaderBarUser = () => {
  const navigate = useNavigate();
  const { user, handleLogout } = useLogin();

  const userMenu: NavigationLink[] = [
    {
      label: "Settings",
      pathname: "/settings",
      Icon: Settings,
    },
    {
      Icon: Employees,
      label: "Admins",
      pathname: "/admins",
    },
  ];

  if (!user) return <Spin size="small" />;

  return (
    <>
      <UserMenu
        user={{ name: user.name || "", logo: user.picture || "" }}
        handleLogout={handleLogout}
        navigate={navigate}
        links={userMenu}
        app="admin"
      />
    </>
  );
};

export default HeaderBarUser;

