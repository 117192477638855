import Form from "antd/es/form";
import notification from "antd/es/notification";
import { UploadFile } from "antd/es/upload";
import { FC, useEffect, useState } from "react";
import { ProfileEditModalFunctions } from "..";
import {
  InitialTeamMember,
  TeamMember,
  TeamMemberRequest,
} from "../../../../../features/entities/core-entities";
import { Provider } from "../../../../../features/entities/providers-entities";
import handleDispatchResponse from "../../../../../features/helpers/handle-dispatch-response";
import useMediaQuery from "../../../../../features/helpers/use-media-query";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import { Tablet } from "../../../../common/Media";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import { ModalConfirm } from "../../../../common/hd-modal";
import ModalWithHeaderFooter from "../../../../common/hd-modal/layouts/modal-with-header-footer";
import TeamList from "../../../../provider/profile/profile-team/team-list";
import TeamMemberForm from "./team-member-form";

type TeamEditProps = {
  activeMemberId?: string;
  setFooter: (footer?: JSX.Element) => void;
  functions: ProfileEditModalFunctions;
  provider: Provider;
  teamMembers: TeamMember[];
  handleCancel: () => void;
};

const TeamEdit: FC<TeamEditProps> = ({
  activeMemberId,
  setFooter,
  functions,
  provider,
  teamMembers,
  handleCancel,
}) => {
  const theme = useTheme();

  const [selectedMember, setSelectedMember] =
    useState<TeamMember>(InitialTeamMember);

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  useEffect(() => {
    setFooter(undefined);
  }, []);

  const prepareTeamMember = () => {
    setSelectedMember(
      teamMembers.find((m) => m.provider_team_member_id === activeMemberId) ||
        InitialTeamMember
    );
  };
  useEffect(() => {
    prepareTeamMember();
  }, [activeMemberId]);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const request: TeamMemberRequest = {
          name: values.name,
          role: values.role,
          linked_in: values.linked_in,
          provider_id: provider.provider_id,
        };
        const data = new FormData();

        data.append(
          "file1",
          fileList[0] !== undefined ? (fileList[0].originFileObj as Blob) : ""
        );

        data.append("request", JSON.stringify(request));

        if (selectedMember.provider_team_member_id) {
          const teamMemberId = selectedMember.provider_team_member_id;
          await functions
            .editTeamMember({ data, teamMemberId })
            .then((res) =>
              handleDispatchResponse(
                res,
                t("notification.saved"),
                t("notification.saving_error")
              )
            );
        } else {
          await functions
            .submitTeamMember({ data })
            .then((res) =>
              handleDispatchResponse(
                res,
                t("notification.saved"),
                t("notification.saving_error")
              )
            );
        }
        prepareTeamMember();
        if (isTablet) handleCancel();
      })
      .catch((error) =>
        notification.error({
          message: error.errorFields[0].errors[0],
          placement: "top",
        })
      );
  };

  const handleDelete = () => {
    ModalConfirm({
      title: t("profile|assetsModal.confirm"),
      content: t("profile|assetsModal.delete.team"),
      okText: t("profile|assetsModal.confirm"),
      cancelText: t("profile|assetsModal.cancel"),

      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      onOk() {
        if (!selectedMember.provider_team_member_id) {
          notification.error({
            message: t("provider-profile|team.empty.error"),
            placement: "top",
          });
          return;
        }
        return new Promise<void>((resolve, reject) => {
          functions
            .removeTeamMember({
              teamMemberID: selectedMember.provider_team_member_id,
            })
            .then((res) => {
              handleDispatchResponse(
                res,
                t("notification.saved"),
                t("notification.saving_error")
              );
              setSelectedMember(InitialTeamMember);
              resolve();
            })
            .catch((error) =>
              notification.error({
                message: error.errorFields[0].errors[0],
                placement: "top",
              })
            );
        }).catch(() => console.error("Oops errors!"));
      },
    });
  };

  const Footer = () => (
    <ModalWithHeaderFooter.Footer
      actions={
        <>
          {selectedMember.provider_team_member_id ? (
            <Button
              defaultStyle={theme.colors.red_1}
              onClick={handleDelete}
              size="small"
              loading={functions.isLoading}
            >
              {t("button.delete")}
            </Button>
          ) : (
            <Button
              defaultStyle={theme.colors.transparent}
              onClick={handleCancel}
              size="small"
              loading={functions.isLoading}
            >
              {t("button.cancel")}
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            size="small"
            loading={functions.isLoading}
          >
            {t("button.save")}
          </Button>
        </>
      }
    />
  );

  return (
    <Div flex="row">
      <Div flex="column" justify="stretch">
        <div style={{ position: "sticky", top: 0 }}>
          <div style={{ padding: "15px 20px", flex: 1 }}>
            <TeamMemberForm
              form={form}
              teamMember={selectedMember}
              fileList={fileList}
              setFileList={setFileList}
              handleSubmit={handleSubmit}
            />
          </div>
          <Footer />
        </div>
      </Div>
      <Tablet direction="up">
        <TeamList
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          teamMembers={teamMembers}
          isEditable
        />
      </Tablet>
    </Div>
  );
};

export default TeamEdit;

