import { IconProps } from "../features/entities/general";

const BurgerMenu = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.60001 6.39999H30.4M1.60001 16H30.4M1.60001 25.6H30.4"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default BurgerMenu;
