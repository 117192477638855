import Divider from "antd/es/divider";
import { useEffect, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  FormBuilderGroup,
  ItemsTypes,
} from "../../../../../features/entities/form-entities";
import theme from "../../../../../theme";
import Div from "../../../../common/div";
import { DragDropContainer } from "../../styles";
import { FormBuilderPageProps } from "../../types";
import FormGroupContent from "../group";
import FormPageContentButtons from "./form-page-content-buttons";
import FormPageHeader from "./form-page-content-header";

const FormBuilderPage = ({
  groups,
  id,
  isFirstPage,
  functions,
  formPageResponse,
  pages,
  locale,
}: FormBuilderPageProps) => {
  const isEndPage = formPageResponse?.page_type?.includes("end");
  const isOpeningPage = formPageResponse?.page_type?.includes("opening");
  const [formGroups, setFormGroups] = useState<{
    [key: string]: FormBuilderGroup;
  }>(groups);

  useEffect(() => {
    setFormGroups(groups);
  }, [groups]);

  const groupsList = useMemo(
    () =>
      !!formGroups
        ? Object.values(formGroups).sort(
            (group1, group2) =>
              group1.entity.group_order - group2.entity.group_order
          )
        : [],
    [formGroups]
  );
  return (
    <Div
      borderColor={theme.colors.primary}
      backgroundColor={theme.colors.white_1}
      flex={isEndPage ? "row" : "column"}
    >
      <FormPageHeader
        page={formPageResponse}
        isEndPage={isEndPage}
        isOpeningPage={isOpeningPage}
        formPageId={id}
        functions={functions}
        locale={locale}
      />
      <div
        style={{
          padding: 24,
          borderTop: !isEndPage && `1px solid ${theme.colors.grey_4}`,
          borderBottom: !isEndPage && `1px solid ${theme.colors.grey_4}`,
          borderLeft: isEndPage && `1px solid ${theme.colors.grey_4}`,
          flex: 1,
        }}
      >
        <Droppable
          droppableId={`page_droppable_${id}`}
          type={ItemsTypes.groups}
        >
          {(provided) => (
            <DragDropContainer
              $isDroppable
              style={{
                minHeight: 100,
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {groupsList.map((builderGroup, index) => (
                <Draggable
                  key={`group_draggable_${builderGroup.id}`}
                  draggableId={`group_draggable_${builderGroup.id}`}
                  index={index}
                >
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DragDropContainer $isMovable>
                          <FormGroupContent
                            builderGroup={builderGroup}
                            formPageId={formPageResponse.form_page_id}
                            pages={pages}
                            functions={functions}
                            locale={locale}
                          />
                        </DragDropContainer>
                        {index < groupsList.length - 1 && (
                          <Divider
                            style={{
                              margin: "32px 0",
                              borderColor: theme.colors.primary,
                            }}
                          />
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              ))}

              {provided.placeholder}
            </DragDropContainer>
          )}
        </Droppable>
      </div>

      <FormPageContentButtons
        isFirstPage={isFirstPage}
        isEndPage={isEndPage}
        isOpeningPage={isOpeningPage}
      />
    </Div>
  );
};

export default FormBuilderPage;

