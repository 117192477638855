import Button from "antd/es/button";
import { useState } from "react";
import ReactQuill from "react-quill";

type EditableTextAreaProps = {
  value: string;
  onSave: any;
};

const EditableTextArea = ({ value, onSave }: EditableTextAreaProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    onSave(newValue);
  };

  return (
    <div>
      {isEditing ? (
        <ReactQuill
          className="text-editor"
          value={newValue}
          onChange={setNewValue}
          style={{ border: "1px solid #ccc", minHeight: "150px" }}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      )}
      {isEditing ? (
        <Button type="primary" onClick={handleSave} size="small">
          Save
        </Button>
      ) : (
        <Button onClick={handleEdit}>Edit</Button>
      )}
    </div>
  );
};

export default EditableTextArea;
