import Div from "@hellodarwin/core/lib/components/common/div";
import { DealHubspotResponse } from "@hellodarwin/core/lib/features/entities";
import { Select } from "antd/lib";
import { useEffect, useState } from "react";
import { useAdminApi } from "../../features/api/use-admin-api";

type ProgramsSearchProps = {
  disabled?: boolean;
  companyId?: string;
  handleSelect?: (value: string) => void;
};

const ProgramsSearch = ({
  disabled,
  companyId,
  handleSelect,
}: ProgramsSearchProps) => {
  const api = useAdminApi();
  const [hubspotDeals, setHubspotDeals] = useState<DealHubspotResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initOptions = async () => {
    if (!companyId) return;
    setLoading(true);
    const crmID = await api.getCompanyCRMID(companyId);

    const hubspotDeals = crmID ? await api.getCompanyHubspotDeals(crmID) : [];
    setHubspotDeals(hubspotDeals ?? []);
    setLoading(false);
  };

  return (
    <Div style={{ width: "100%" }}>
      <Select
        disabled={disabled || loading}
        style={{ width: "100%" }}
        options={hubspotDeals.map((deal) => {
          return { label: deal.dealname, value: deal.deal_id };
        })}
        placeholder={
          loading
            ? "Loading"
            : disabled
              ? "New program"
              : hubspotDeals.length === 0
                ? "No programs for this company"
                : "Search for a program"
        }
        value={!companyId ? "No company" : disabled ? "New Program" : undefined}
        onChange={handleSelect}
      />
    </Div>
  );
};

export default ProgramsSearch;

