import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import DarwinError from "@hellodarwin/icons/dist/graphics/DarwinError";
import { useNavigate } from "react-router-dom";

const UnverifiedAdminPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };
  return (
    <Div
      className="network-error"
      align="center"
      justify="center"
      style={{ height: "100vh" }}
      flex="column"
    >
      <DarwinError width={600} />

      <Div flex="column" gap={32} align="center">
        <Div flex="column" gap={8}>
          <Typography.Title
            textAlign="center"
            level={1}
            elementTheme="h4"
            noMargin
          >
            {t("houstonProblem")}
          </Typography.Title>
          <Typography
            textAlign="center"
            elementTheme="body1"
            color={theme.colors.grey_2}
          >
            You are unverified! Please contact your team lead.
          </Typography>
        </Div>
        <Button
          fitContent
          onClick={refreshPage}
          defaultStyle={theme.colors.grey_1}
        >
          {t("refresh")}
        </Button>
      </Div>
    </Div>
  );
};

export default UnverifiedAdminPage;

