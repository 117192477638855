import { IconProps } from "../features/entities/general";

const LineViewOutline = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.77778 2.86137C1.77778 2.26291 2.2326 1.77777 2.79365 1.77777H29.2064C29.7674 1.77777 30.2222 2.26291 30.2222 2.86137V7.07263C30.2222 7.67109 29.7674 8.15623 29.2064 8.15623H16H2.79365C2.2326 8.15623 1.77778 7.67109 1.77778 7.07263V2.86137Z"
          stroke="currentColor"
          strokeWidth="1.8"
        />
        <path
          d="M1.77778 13.8944C1.77778 13.2959 2.2326 12.8108 2.79365 12.8108H29.2064C29.7674 12.8108 30.2222 13.2959 30.2222 13.8944V18.1056C30.2222 18.7041 29.7674 19.1892 29.2064 19.1892H2.79365C2.2326 19.1892 1.77778 18.7041 1.77778 18.1056V13.8944Z"
          stroke="currentColor"
          strokeWidth="1.8"
        />
        <path
          d="M1.77778 24.9274C1.77778 24.3289 2.2326 23.8438 2.79365 23.8438H29.2064C29.7674 23.8438 30.2222 24.3289 30.2222 24.9274V29.1386C30.2222 29.7371 29.7674 30.2222 29.2064 30.2222H2.79365C2.2326 30.2222 1.77778 29.7371 1.77778 29.1386V24.9274Z"
          stroke="currentColor"
          strokeWidth="1.8"
        />
      </svg>
    </>
  );
};

export default LineViewOutline;
