import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  AdminProfile,
  AdminProfileInitialState,
  ApiError,
  ApiErrorInitialState,
} from "@hellodarwin/core/lib/features/entities";
import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

export const me = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient },
  { rejectValue: ApiError }
>("admin/me", async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
  try {
    return await api.me();
  } catch (err: any) {
    showErrorNotification(err.response.data.error_code);
    return rejectWithValue(err.response.data);
  }
});

export interface GlobalState {
  status: "idle" | "pending";
  error: ApiError;
  profile: AdminProfile;
}

const initialState: GlobalState = {
  status: "idle",
  error: ApiErrorInitialState,
  profile: AdminProfileInitialState,
};

const globalSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(me.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(me.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.status = "idle";
    });
    builder.addCase(me.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const selectIsLoading = (state: RootState) =>
  state.global.status === "pending";

export const selectAuthentifiedUser = (state: RootState) =>
  state.global.profile;

export const globalReducer = globalSlice.reducer;

