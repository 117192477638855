import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import SingleGrant from "@hellodarwin/core/lib/components/grants/single";
import Loading from "@hellodarwin/core/lib/components/loading";
import { WebsiteGrantResult } from "@hellodarwin/core/lib/features/entities";
import SingleGrantSections from "@hellodarwin/core/lib/features/enums/single-grant-sections";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchGrant,
  selectGrantById,
} from "../../../features/api/slices/grants-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";

const FundingExplorerSingleGrantPage = () => {
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const params = useParams<{
    id: string;
  }>();
  const location = useLocation();
  const grant = useAppSelector((state) =>
    selectGrantById(state, params.id || "")
  );

  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(fetchGrant({ api, grantId: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    location.pathname.startsWith("/search-grants") ?
      navigate("/search-grants") :
      navigate("/funding-explorer");
  };

  if (!params.id) return <Loading />;

  if (!grant) {
    return <Loading />;
  }

  const queriedGrant = Object.entries(grant).reduce((o, entry) => {
    const [key, value] = entry;
    if (key.includes("en") || key.includes("fr")) {
      if (selectedLocale === "en" && key.includes("en")) {
        return { ...o, [key.replace("_en", "")]: value };
      } else if (selectedLocale === "fr" && key.includes("fr")) {
        return { ...o, [key.replace("_fr", "")]: value };
      } else {
        return { ...o };
      }
    } else {
      return { ...o, [key]: value };
    }
  }, {});

  return (
    <PageLayout
      app="admin"
      tabTitle={grant?.grant_title_en}
      title={grant?.grant_title_en}
      handleBack={handleBack}
      breadcrumbs={
        [
          location.pathname.startsWith("/search-grants") ?
            {
              breadcrumbName: "Search Grants",
              path: "/search-grants",
            } :
            {
              breadcrumbName: "Funding Explorer",
              path: "/funding-explorer",
            },
          {
            breadcrumbName: !grant
              ? "Single Grant"
              : selectedLocale === "en"
                ? grant.grant_display_title_en
                : grant.grant_display_title_fr,
          },
      ]}
    >
      <SingleGrant
        grant={queriedGrant as WebsiteGrantResult}
        sections={[
          SingleGrantSections.Summary,
          SingleGrantSections.Preview,
          SingleGrantSections.Projects,
          SingleGrantSections.Admissibility,
          SingleGrantSections.Criteria,
          SingleGrantSections.Register,
          SingleGrantSections.OtherInfo,
          SingleGrantSections.Documents,
          SingleGrantSections.Contact,
        ]}
      />
    </PageLayout>
  );
};

export default FundingExplorerSingleGrantPage;

