import UserOutlined from "@ant-design/icons/UserOutlined";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { PotentialProviderMatch } from "@hellodarwin/core/lib/features/entities";
import { Col, Row } from "antd/es/grid";
import Modal from "antd/es/modal";

import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import "./potential-provider-modal.scss";

interface PotentialProvidersModalProps {
  modalVisible: boolean;
  matches: PotentialProviderMatch[];
  closeModal: () => void;
}

const PotentialProvidersModal = ({
  modalVisible,
  matches,
  closeModal,
}: PotentialProvidersModalProps) => {
  return (
    <Modal
      title={`Potential Matches ${
        matches && Object.keys(matches).length > 0 && matches.length
      }`}
      open={modalVisible}
      okText="Ok"
      onOk={closeModal}
      onCancel={closeModal}
      keyboard={true}
    >
      {!matches || Object.keys(matches).length === 0 ? (
        <Typography elementTheme="body1">No matches found</Typography>
      ) : (
        <Grid xl={1}>
          <div className="scroll-div">
            <div className="div-header">
              <Row gutter={24} style={{ marginBottom: "1rem" }}>
                <Col>
                  <Typography
                    elementTheme="body1"
                    className="company-name-title"
                  >
                    Company Name
                  </Typography>
                </Col>
                <Col>
                  <Typography elementTheme="body1" className="score-amount">
                    Score
                  </Typography>
                </Col>
                <hr className="divider-div" />
              </Row>
            </div>

            {matches?.map((match) => {
              return (
                <div className="div-color-change" key={match.provider_id}>
                  <Row gutter={24} className="row-provider">
                    <UserOutlined className="user-icon" />
                    <Col>
                      <Typography className="provider-name">
                        {match.name}
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className="score-value">
                        {match.score}
                      </Typography>
                    </Col>
                  </Row>
                  <hr className="divider-div-large" />
                </div>
              );
            })}
          </div>
        </Grid>
      )}
    </Modal>
  );
};

export default PotentialProvidersModal;

