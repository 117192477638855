import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";

import {
  AdminProfile,
  AdminProfileInitialState,
  ApiError,
  ApiErrorInitialState,
} from "@hellodarwin/core/lib/features/entities";
import { RootState } from "../../../app/app-store";
import { showErrorNotification } from "../../utils";
import AdminApiClient from "../admin-api-client";

export const fetchAdminById = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient; aminId: string },
  { rejectValue: ApiError }
>(
  "admim/fetchAdminById",
  async (
    { api, aminId }: { api: AdminApiClient; aminId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchAdminById(aminId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAdmins = createAsyncThunk<
  AdminProfile[],
  { api: AdminApiClient },
  { rejectValue: ApiError }
>(
  "admim/fetchAdmins",
  async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
    try {
      return await api.getAdmins();
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);
export const refreshAdmins = createAsyncThunk<
  AdminProfile[],
  { api: AdminApiClient },
  { rejectValue: ApiError }
>(
  "admim/refreshAdmins",
  async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
    try {
      return await api.refreshAdmins();
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient; data: AdminProfile },
  { rejectValue: ApiError }
>(
  "admin/updateAdmin",
  async (
    { api, data }: { api: AdminApiClient; data: AdminProfile },
    { rejectWithValue }
  ) => {
    try {
      return await api.updateAdmin(data);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verifyAdmin = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient; data: AdminProfile },
  { rejectValue: ApiError }
>(
  "admin/verifyAdmin",
  async (
    { api, data }: { api: AdminApiClient; data: AdminProfile },
    { rejectWithValue }
  ) => {
    try {
      return await api.verifyAdmin(data);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdmin = createAsyncThunk<
  string,
  { api: AdminApiClient; data: AdminProfile },
  { rejectValue: ApiError }
>(
  "admin/deleteAdmin",
  async (
    { api, data }: { api: AdminApiClient; data: AdminProfile },
    { rejectWithValue }
  ) => {
    try {
      return await api.deleteAdmin(data);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const adminAdapter = createEntityAdapter({
  selectId: (model: AdminProfile) => model.admin_id,
});

export interface GlobalState {
  status: "idle" | "pending";
  error: ApiError;
  profile: AdminProfile;
  admins: EntityState<AdminProfile, string>;
}

const initialState: GlobalState = {
  status: "idle",
  error: ApiErrorInitialState,
  profile: AdminProfileInitialState,
  admins: adminAdapter.getInitialState(),
};

const adminsSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdmins.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAdmins.fulfilled, (state, { payload }) => {
      adminAdapter.setAll(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(fetchAdmins.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(refreshAdmins.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(refreshAdmins.fulfilled, (state, { payload }) => {
      adminAdapter.setAll(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(refreshAdmins.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchAdminById.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAdminById.fulfilled, (state, { payload }) => {
      adminAdapter.setOne(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(fetchAdminById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(verifyAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(verifyAdmin.fulfilled, (state, { payload }) => {
      adminAdapter.updateOne(state.admins, {
        id: payload.admin_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(verifyAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(updateAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateAdmin.fulfilled, (state, { payload }) => {
      adminAdapter.updateOne(state.admins, {
        id: payload.admin_id,
        changes: payload,
      });
      state.status = "idle";
    });
    builder.addCase(updateAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(deleteAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(deleteAdmin.fulfilled, (state, { payload }) => {
      adminAdapter.removeOne(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(deleteAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectAllAdmins, selectById: selectAdminById } =
  adminAdapter.getSelectors((state: RootState) => state.admins.admins);

export const selectAdminIsLoading = (state: RootState) =>
  state.admins.status === "pending";

export const adminReducer = adminsSlice.reducer;

