import { IconProps } from "../features/entities/general";

const Refereral = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2569 8.34991H22.0497M11.2569 14.3485H16.1289M27.1963 10.9785V2.97318C27.1963 2.15646 26.5336 1.49438 25.7161 1.49438H2.94366C2.12616 1.49438 1.46345 2.15646 1.46345 2.97318V25.7238C1.46345 26.5405 2.12616 27.2026 2.94366 27.2026H10.1886M25.4839 25.52L30.9113 20.0978M30.9113 20.0978L25.4839 14.6755M30.9113 20.0978H20.0564C18.617 20.0978 17.2365 20.669 16.2187 21.6859C15.2008 22.7028 14.629 24.0819 14.629 25.52C14.629 26.9581 15.2008 28.3372 16.2187 29.3541C17.2365 30.371 18.617 30.9422 20.0564 30.9422H22.7702M7.05972 8.34991H7.06811V8.35905H7.05972V8.34991ZM7.50942 8.34991C7.50942 8.46355 7.46204 8.57253 7.3777 8.65289C7.29337 8.73324 7.17898 8.77838 7.05972 8.77838C6.94045 8.77838 6.82606 8.73324 6.74173 8.65289C6.65739 8.57253 6.61002 8.46355 6.61002 8.34991C6.61002 8.23628 6.65739 8.12729 6.74173 8.04694C6.82606 7.96659 6.94045 7.92144 7.05972 7.92144C7.17898 7.92144 7.29337 7.96659 7.3777 8.04694C7.46204 8.12729 7.50942 8.23628 7.50942 8.34991ZM7.05972 14.3485H7.06811V14.3576H7.05972V14.3485ZM7.50942 14.3485C7.50942 14.4621 7.46204 14.5711 7.3777 14.6515C7.29337 14.7318 7.17898 14.777 7.05972 14.777C6.94045 14.777 6.82606 14.7318 6.74173 14.6515C6.65739 14.5711 6.61002 14.4621 6.61002 14.3485C6.61002 14.2349 6.65739 14.1259 6.74173 14.0455C6.82606 13.9652 6.94045 13.92 7.05972 13.92C7.17898 13.92 7.29337 13.9652 7.3777 14.0455C7.46204 14.1259 7.50942 14.2349 7.50942 14.3485ZM7.05972 20.3471H7.06811V20.3562H7.05972V20.3471ZM7.50942 20.3471C7.50942 20.4607 7.46204 20.5697 7.3777 20.6501C7.29337 20.7304 7.17898 20.7756 7.05972 20.7756C6.94045 20.7756 6.82606 20.7304 6.74173 20.6501C6.65739 20.5697 6.61002 20.4607 6.61002 20.3471C6.61002 20.2335 6.65739 20.1245 6.74173 20.0441C6.82606 19.9638 6.94045 19.9186 7.05972 19.9186C7.17898 19.9186 7.29337 19.9638 7.3777 20.0441C7.46204 20.1245 7.50942 20.2335 7.50942 20.3471Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Refereral;
