import Div from "@hellodarwin/core/lib/components/common/div";
import {
  Contact,
  GrantResult,
  Program,
  ProgramGrantStatus,
  ProgramType,
} from "@hellodarwin/core/lib/features/entities";
import Form from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { useEffect, useState } from "react";

import Close from "@hellodarwin/icons/dist/icons/Close";
import Radio from "antd/es/radio";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchAdmins,
  selectAdminIsLoading,
  selectAllAdmins,
} from "../../../features/api/slices/admins-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import useDebounce from "../../../hooks/use-debounce";
import GrantsSearch from "../../grants/grants-search";

const SEARCH_DELAY_MS = 500;
export const EMAIL_ALREADY_EXISTS_ERROR = 5023;

type CreateProgramFormProps = {
  visible: boolean;
  handleClose: () => void;
  companyId?: string;
};

export const ProgramGrantStatusProd = [
  "ongoing_information_required",
  "ongoing_work_hellodarwin",
  "validation_required",
  "completed_to_submit",
  "submitted_waiting_for_results",
  "accepted",
  "refused",
  "not_submited",
];

const CreateProgramForm = ({
  visible,
  handleClose,
  companyId,
}: CreateProgramFormProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedGrant, setSelectedGrant] = useState<GrantResult | undefined>();
  const admins = useAppSelector(selectAllAdmins);
  const isAdminsLoading = useAppSelector(selectAdminIsLoading);

  const handleSubmitGrant = (grant: GrantResult) => {
    setSelectedGrant(grant);
    form.setFieldValue("program_grant_id", grant.grant_id);
  };

  useEffect(
    () => {
      setSelectedGrant(undefined);
      form.setFieldValue("program_grant_status", "identified_opportunitites");
      dispatch(fetchAdmins({ api }));
      if (companyId) {
        initContacts();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initContacts = async () => {
    try {
      const response = await api.queryCompanyContacts(companyId!, 1, 8, "");
      setContacts(response);
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const onFinish = async (values: any) => {
    setIsSaving(true);
    try {
      let newProgram: Program = {
        ...values,
      };

      if (isNewContact && !companyId) {
        const contactFetch = await api.getContactByEmail(values.contact_email);
        if (contactFetch) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: { data: { error_code: EMAIL_ALREADY_EXISTS_ERROR } },
          };
        }
        const company = await api.createCompany({
          ...values,
        });
        const contact = await api.fetchCompanyContacts(company.company_id);
        newProgram.program_contact_id = contact[0].contact_id;
        newProgram.program_company_id = company.company_id;
      }

      if (companyId) {
        newProgram.program_company_id = companyId;
      }

      newProgram.program_name = selectedGrant?.grant_title;
      if (selectedContact) {
        newProgram.program_contact_id = selectedContact.contact_id;
        newProgram.program_company_id = selectedContact.company_id;
        newProgram.contacts = [selectedContact];
      }

      newProgram.program_type = ProgramType.GrantsRoadmap;

      const program = await api.createProgram(newProgram);

      setSelectedGrant(undefined);
      setIsSaving(false);
      handleClose();
      if (!companyId) {
        navigate("/programs/" + program.program_id);
      }
      message.success("Saved!");
    } catch (e: any) {
      setIsSaving(false);

      if (e.response?.data?.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: "Email already exists",
          description: "This email is already associated with a contact",
        });
        return;
      }

      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const onSearch = async (query: string) => {
    if (query === "" && !companyId) return;

    setIsSearching(true);
    try {
      const response = companyId
        ? await api.queryCompanyContacts(companyId, 1, 8, query)
        : await api.queryContacts(1, 8, query, true);
      setContacts(response);
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    } finally {
      setIsSearching(false);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  const adminOptions = admins.map((admin) => (
    <Select.Option key={admin.admin_id} value={admin.admin_id}>
      {admin.first_name} {admin.last_name}
    </Select.Option>
  ));

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <Modal
      title="Create Program"
      afterClose={() => {
        setIsNewContact(false);
        setSelectedContact(undefined);
        setSelectedGrant(undefined);
        form.resetFields();
      }}
      open={visible}
      okText="Create"
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-program"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Program manager"
              name="program_account_manager"
              rules={[
                { required: true, message: "program manager is required" },
              ]}
            >
              <Select showSearch loading={isAdminsLoading}>
                {adminOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label="Grant"
              name="program_grant_id"
              rules={[{ required: true, message: "grant is required" }]}
            >
              <Div flex="row">
                <GrantsSearch handleSubmit={handleSubmitGrant} />
                {selectedGrant && (
                  <Div
                    flex="row"
                    align="center"
                    justify="center"
                    style={{ width: "fit-content", marginLeft: 8 }}
                  >
                    <Tag
                      closeIcon={<Close height={8} width={8} />}
                      onClose={() => {
                        setSelectedGrant(undefined);
                        form.setFieldValue("program_grant_id", undefined);
                      }}
                    >
                      {selectedGrant.grant_title
                        ? selectedGrant.grant_title.length > 20
                          ? selectedGrant.grant_title?.substring(0, 20) + "..."
                          : selectedGrant.grant_title
                        : ""}
                    </Tag>
                  </Div>
                )}
              </Div>
            </Form.Item>
            <Form.Item
              label="Status"
              name="program_grant_status"
              onReset={() =>
                form.setFieldValue(
                  "program_grant_status",
                  "identified_opportunitites"
                )
              }
            >
              <Select
                onChange={(e) => {
                  form.setFieldValue("program_grant_status", e);
                }}
              >
                {(
                  Object.keys(
                    ProgramGrantStatus
                  ) as (keyof typeof ProgramGrantStatus)[]
                ).map((key, index) => (
                  <Select.Option key={index} value={ProgramGrantStatus[key]}>
                    {ProgramGrantStatus[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Div style={{ marginBottom: 15 }}>
              {companyId ? (
                <>
                  <Form.Item
                    label="Contact"
                    name="program_contact_id"
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    rules={[
                      { required: true, message: "input contact is required" },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      loading={isSearching}
                      filterOption={false}
                      showSearch
                      placeholder="Search by contact name or email"
                      defaultActiveFirstOption={false}
                      onSelect={(value) => {
                        if (!value) {
                          setSelectedContact(
                            contacts.find((c) => c.contact_id === undefined)
                          );
                        } else {
                          setSelectedContact(
                            contacts.find((c) => c.contact_id === value)
                          );
                        }
                        if (companyId) {
                          form.setFieldValue(
                            "program_contact_id",
                            value ?? "placeholder"
                          );
                        }
                      }}
                      onSearch={handleSearch}
                    >
                      {contacts.map((c) => (
                        <Select.Option key={c.contact_id} value={c.contact_id}>
                          {c.name} / {c.email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Div
                    flex="row"
                    justify="space-between"
                    style={{ marginBottom: 15 }}
                  >
                    <Typography.Text strong>For: </Typography.Text>
                    <Radio.Group
                      options={[
                        { label: "Existing Contact", value: "0" },
                        { label: "New Contact", value: "1" },
                      ]}
                      onChange={(e) => setIsNewContact(e.target.value === "1")}
                      value={isNewContact ? "1" : "0"}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Div>

                  {!isNewContact ? (
                    <Select
                      style={{ width: "100%" }}
                      loading={isSearching}
                      filterOption={false}
                      showSearch
                      placeholder="Search by contact name or email"
                      defaultActiveFirstOption={false}
                      onSelect={(value) => {
                        if (!value) {
                          setSelectedContact(
                            contacts.find((c) => c.contact_id === undefined)
                          );
                        } else {
                          setSelectedContact(
                            contacts.find((c) => c.contact_id === value)
                          );
                        }
                        if (companyId) {
                          form.setFieldValue(
                            "program_contact_id",
                            value ?? "placeholder"
                          );
                        }
                      }}
                      onSearch={handleSearch}
                    >
                      {contacts.map((c) => (
                        <Select.Option key={c.contact_id} value={c.contact_id}>
                          {c.name} / {c.email}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <>
                      <Col span={24}>
                        <Form.Item
                          label="Name"
                          name="contact_name"
                          rules={[
                            {
                              required: true,
                              message: "input name is required",
                            },
                          ]}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Email"
                          name="contact_email"
                          rules={[
                            {
                              required: true,
                              message: "input email is required",
                            },
                            {
                              type: "email",
                              message: "must be a valid email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Language"
                          name="project_language"
                          rules={[
                            {
                              required: true,
                              message: "Please select a preferred language",
                            },
                          ]}
                        >
                          <Select placeholder="Select option">
                            <Select.Option value="fr">French</Select.Option>
                            <Select.Option value="en">English</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProgramForm;

