import FieldTimeOutlined from "@ant-design/icons/FieldTimeOutlined";
import FileOutlined from "@ant-design/icons/FileOutlined";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Milestone } from "@hellodarwin/core/lib/features/entities";
import {
  getFormattedDate,
  getShortFormattedDate,
} from "@hellodarwin/core/lib/features/helpers";
import Card from "antd/es/card/Card";
import Descriptions from "antd/es/descriptions";
import { Col, Row } from "antd/es/grid";
import List from "antd/es/list";
import Space from "antd/es/space";
import Tooltip from "antd/es/tooltip";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../app";
import {
  selectMilestonesAttachmentLoading,
  selectMilestonesAttachmentsByMilestoneId,
} from "../../features/api/slices/milestones-attachments-slice";
import { updateMilestone } from "../../features/api/slices/milestones-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import AdminTag from "../single-project-page/admin-tag";
import EditableTextArea from "./editable-text";
import MilestoneAssetUpload from "./milestone-asset-upload";

type MilestoneCardProps = {
  milestone: Milestone;
  index: number;
};

const MilestoneCard = ({ milestone, index }: MilestoneCardProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const [title, setTitle] = useState(milestone.milestone_title);
  const [description, setDescription] = useState(
    milestone.milestone_description
  );
  const [titleEnd, setTitleEnd] = useState(false);

  const [recap, setRecap] = useState(milestone.milestone_recap);

  const attachments = useAppSelector((state: RootState) =>
    selectMilestonesAttachmentsByMilestoneId(state, milestone.milestone_id)
  );

  const loading = useAppSelector(selectMilestonesAttachmentLoading);

  useEffect(() => {
    if (titleEnd) {
      dispatch(
        updateMilestone({
          api: api,
          milestone: { ...milestone, milestone_title: title },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleEnd]);

  return (
    <Card key={index}>
      <Descriptions
        layout="vertical"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        size="small"
        title={
          <Space style={{ marginTop: 2 }}>
            <Title
              level={2}
              className="h5"
              style={{ margin: "15px" }}
              editable={{
                tooltip: "click to edit text",
                onChange: setTitle,
                text: title,
                onEnd: () => {
                  setTitleEnd(true);
                },
                maxLength: 100,
              }}
            >
              {title}
            </Title>
            <AdminTag
              text={milestone.milestone_status}
              tooltip={milestone.milestone_status}
            />
            <AdminTag
              text={milestone.milestone_type}
              tooltip={milestone.milestone_type}
            />
          </Space>
        }
      >
        <Descriptions.Item
          label="Description"
          style={{ paddingRight: "0" }}
          span={1}
        >
          <EditableTextArea
            value={description || ""}
            onSave={(newContent: string) => {
              setDescription(newContent);
              dispatch(
                updateMilestone({
                  api: api,
                  milestone: {
                    ...milestone,
                    milestone_description: newContent,
                  },
                })
              );
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Recap" span={3}>
          <EditableTextArea
            value={recap || ""}
            onSave={(newContent: string) => {
              setRecap(newContent);
              dispatch(
                updateMilestone({
                  api: api,
                  milestone: {
                    ...milestone,
                    milestone_recap: newContent,
                  },
                })
              );
            }}
          />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        layout="vertical"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        size="small"
      >
        {milestone.milestone_created_at && (
          <Descriptions.Item label="Created at">
            <Tooltip title={getFormattedDate(milestone.milestone_created_at)}>
              {milestone.milestone_created_at
                ? getShortFormattedDate(milestone.milestone_created_at)
                : ""}
            </Tooltip>
          </Descriptions.Item>
        )}
        {milestone.milestone_scheduled_at && (
          <Descriptions.Item label="Scheduled at">
            <Tooltip title={getFormattedDate(milestone.milestone_scheduled_at)}>
              {milestone.milestone_scheduled_at
                ? getShortFormattedDate(milestone.milestone_scheduled_at)
                : ""}
            </Tooltip>
          </Descriptions.Item>
        )}
        {milestone.milestone_dued_at && (
          <Descriptions.Item label="Dued at">
            <Tooltip title={getFormattedDate(milestone.milestone_dued_at)}>
              {milestone.milestone_dued_at
                ? getShortFormattedDate(milestone.milestone_dued_at)
                : ""}
            </Tooltip>
          </Descriptions.Item>
        )}
        {milestone.milestone_completed_at && (
          <Descriptions.Item label="Completed at">
            <Tooltip title={getFormattedDate(milestone.milestone_completed_at)}>
              {milestone.milestone_completed_at
                ? getShortFormattedDate(milestone.milestone_completed_at)
                : ""}
            </Tooltip>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Caller" style={{ maxWidth: "100px" }}>
          {milestone.milestone_caller}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        layout="vertical"
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        size="small"
      >
        <Descriptions.Item
          span={2}
          label={
            <Row>
              <Col span={18}>
                <Typography
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "1.5714285714285714",
                  }}
                >
                  Files
                </Typography>{" "}
              </Col>
              <MilestoneAssetUpload
                milestoneId={milestone.milestone_id || ""}
              />
            </Row>
          }
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={attachments}
            loading={loading}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                extra={
                  <Tooltip
                    title={
                      <Card>
                        <Descriptions size="small" layout="vertical">
                          <Descriptions.Item label="File name">
                            {item.milestone_attachment_display_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Created at">
                            {getFormattedDate(
                              item.milestone_attachment_created_at
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="Updated at">
                            {getFormattedDate(
                              item.milestone_attachment_updated_at
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    }
                  >
                    <FieldTimeOutlined />
                  </Tooltip>
                }
              >
                <List.Item.Meta
                  avatar={<FileOutlined />}
                  title={
                    <a href="https://ant.design">
                      {item.milestone_attachment_display_name}
                    </a>
                  }
                  description={item.milestone_attachment_description}
                />
              </List.Item>
            )}
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default MilestoneCard;

