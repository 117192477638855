import Form from "antd/es/form";
import Select, { SelectProps } from "antd/es/select";
import { useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ItemsTypes } from "../../../../features/entities/form-entities";
import { FormListStyled } from "../styles";
import getTypeComponent from "../utils/get-type-component";
import getTypeItems from "../utils/get-type-items";

const FormItemsList = ({ locale }: { locale: string }) => {
  const options: SelectProps["options"] = (
    Object.keys(ItemsTypes) as Array<keyof typeof ItemsTypes>
  ).map((key) => ({
    label: ItemsTypes[key],
    value: ItemsTypes[key],
  }));

  const [activeType, setActiveType] = useState<ItemsTypes>(ItemsTypes.main);

  const activeType_items = useMemo(() => {
    return getTypeItems(activeType);
  }, [activeType]);

  const ActiveType_Component = useMemo(() => {
    return getTypeComponent(activeType);
  }, [activeType]);

  return (
    <FormListStyled>
      <Select options={options} value={activeType} onChange={setActiveType} />
      <Form layout="vertical">
        <Droppable
          droppableId={`controls_droppable_${activeType}`}
          type={activeType}
        >
          {(droppable_provided) => (
            <div
              {...droppable_provided.droppableProps}
              ref={droppable_provided.innerRef}
            >
              {activeType_items.map(({ key }, index) => (
                <Draggable
                  key={`control_draggable_${key}`}
                  draggableId={key}
                  index={index}
                >
                  {(draggable_provided) => (
                    <div
                      ref={draggable_provided.innerRef}
                      {...draggable_provided.draggableProps}
                      {...draggable_provided.dragHandleProps}
                    >
                      <ActiveType_Component type={key as any} locale={locale} />
                    </div>
                  )}
                </Draggable>
              ))}
              {droppable_provided.placeholder}
            </div>
          )}
        </Droppable>
      </Form>
    </FormListStyled>
  );
};

export default FormItemsList;

