import { IconProps } from "../features/entities/general";

const Save = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      className={className}
      onClick={onClick}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8975 19.5533C23.8975 18.392 23.0012 17.3498 21.7754 17.3498H18.7202H15.665H12.6098H9.55458C8.32875 17.3498 7.4325 18.392 7.4325 19.5533V30.3853H5.48097C3.55709 30.3853 1.9 28.704 1.9 26.5056V4.7797C1.9 2.58141 3.55709 0.9 5.48097 0.9H9.06194V7.38682C9.06194 8.5482 9.95821 9.59037 11.184 9.59037H18.5165C19.7423 9.59037 20.6386 8.54821 20.6386 7.38682V0.9H21.7176C22.8639 0.9 23.9761 1.38523 24.8056 2.27001C24.8056 2.27002 24.8056 2.27002 24.8056 2.27003L28.1223 5.80793L28.1224 5.80794C28.9537 6.6947 29.43 7.90913 29.43 9.18664V26.5056C29.43 28.704 27.7729 30.3853 25.849 30.3853H23.8975V25.1749V19.5533ZM22.0975 30.3853H9.2325V19.5533C9.2325 19.2749 9.43055 19.1498 9.55458 19.1498H12.6098H15.665H18.7202H21.7754C21.8994 19.1498 22.0975 19.2749 22.0975 19.5533V25.1749V30.3853ZM10.8619 7.38682V0.9H18.8386V7.38682C18.8386 7.66528 18.6406 7.79037 18.5165 7.79037H11.184C11.06 7.79037 10.8619 7.66528 10.8619 7.38682Z"
        stroke={color}
        stroke-width="1.8"
      />
    </svg>
  );
};

export default Save;

