import FormBuilder from "@hellodarwin/core/lib/components/forms/form-builder";
import { FormBuilderFunctions } from "@hellodarwin/core/lib/components/forms/form-builder/types";
import {
  AdminFormResponse,
  FormGroupResponse,
  FormItemResponse,
  FormPageResponse,
  FormsGroup,
  FormsGroupsItem,
  FormsItemsTotal,
  FormsPage,
  FormsSelectOptions,
  GrantPreview,
  InitialAdminFormResponse,
  InitialFormGroupResponse,
  InitialFormItemResponse,
  InitialFormPageResponse,
  InitialFormsSelectOptions,
  InitialTransferFormGroup,
  TransferFromGroup,
} from "@hellodarwin/core/lib/features/entities";
import getShortId from "@hellodarwin/core/lib/features/helpers/get-short-id";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import message from "antd/es/message";
import { useAppDispatch } from "../../../app";
import {
  createFormGroup,
  createFormItem,
  createFormPage,
  createSingleFormSelectOptions,
  deleteFormGroup,
  deleteFormItem,
  deleteFormPage,
  transferFormGroup,
  updateFormGroup,
  updateFormGroups,
  updateFormItem,
  updateFormItemOrder,
  updateFormPage,
  updateFormPages,
  updateFormSelectOptions,
  updateSingleFormSelectOptions,
} from "../../../features/api/slices/forms-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import FormBuilderHeader from "./form-builder-header";

export interface EditFormBuilderProps {
  formResponse: AdminFormResponse;
}

const EditFormBuilder = ({ formResponse }: EditFormBuilderProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale, changeLanguage } = useLocale();

  const handleLocale = () => {
    if (selectedLocale === "fr") {
      changeLanguage("en");
    } else {
      changeLanguage("fr");
    }
  };

  const formBuilderFunctions: FormBuilderFunctions = {
    createPage: async (formPage: FormsPage): Promise<FormPageResponse> => {
      try {
        const response = await dispatch(
          createFormPage({
            api,
            formPage: formPage,
          })
        ).unwrap();

        const pages = [...formResponse.pages, response]
          .sort((page1, page2) => {
            if (
              page1.form_page_id === response.form_page_id &&
              page1.page_number === page2.page_number
            ) {
              return -1;
            } else {
              return page1.page_number - page2.page_number;
            }
          })
          .map((page, page_number) => ({
            ...page,
            page_number,
          }));

        const updatedResponse: AdminFormResponse = {
          ...formResponse,
          pages,
        };
        await dispatch(updateFormPages({ api, formResponse: updatedResponse }));

        message.success("Form page created");

        return response;
      } catch (error) {
        message.error("Error creating form page");
        console.error(error);
        return InitialFormPageResponse;
      }
    },
    createGroup: async (formGroup: FormsGroup): Promise<FormGroupResponse> => {
      try {
        const response = (formGroup = await dispatch(
          createFormGroup({
            api,
            formGroup: formGroup,
          })
        ).unwrap());
        const formPage = formResponse.pages.find(
          (page) => page.form_page_id === response.form_page_id
        );

        if (!formPage) {
          message.warning("Group Created, but not reordered");
          return response;
        }

        const groups = [...(formPage?.groups ?? []), response]
          .sort((group1, group2) => {
            if (
              group1.form_group_id === response.form_group_id &&
              group1.group_order === group2.group_order
            ) {
              return -1;
            } else {
              return group1.group_order - group2.group_order;
            }
          })
          .map((group, group_order) => ({
            ...group,
            group_order,
          }));

        const updatedPage: FormPageResponse = {
          ...formPage,
          groups,
        };
        await dispatch(updateFormGroups({ api, formPage: updatedPage }));
        message.success("Form group created");
        return response;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
        return InitialFormGroupResponse;
      }
    },
    createItem: async (formItemsTotal: FormsItemsTotal): Promise<string> => {
      try {
        const response = await dispatch(
          createFormItem({
            api,
            formItemTotal: formItemsTotal,
          })
        ).unwrap();

        const formItemsOrders: FormsGroupsItem[] = [];

        const formPage = formResponse.pages.find(
          (g) => g.form_page_id === response.form_group?.form_page_id
        );
        if (!formPage) {
          message.warning("Item Created, but not reordered");
          return response.form_item.form_item_id;
        }

        const group = formPage.groups.find(
          (g) => g.form_group_id === response.form_group?.form_group_id
        );
        if (!group) {
          message.warning("Item Created, but not reordered");
          return response.form_item.form_item_id;
        }

        let isAfterNewItem = false;
        if (!group.items?.length) {
          message.success("Form Item created");
          return response.form_item.form_item_id;
        }
        for (let index = 0; index < group.items.length; index++) {
          const item = group.items[index];

          if (index === response.form_group_item.forms_groups_items_order) {
            formItemsOrders.push({
              form_group_id: group.form_group_id,
              forms_groups_items_id:
                response.form_group_item.forms_groups_items_id,
              forms_groups_items_order: index,
              form_item_id: response.form_item.form_item_id,
            });
            isAfterNewItem = true;
          }

          formItemsOrders.push({
            form_group_id: group.form_group_id,
            forms_groups_items_id: item.forms_groups_items_id,
            forms_groups_items_order: isAfterNewItem ? index + 1 : index,
            form_item_id: item.form_item_id,
          });
        }

        await dispatch(updateFormItemOrder({ api, formItemsOrders }));

        message.success("Form Item created");
        return response.form_item.form_item_id;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
        return "";
      }
    },
    updateFormGroup: async (formGroup: FormGroupResponse) => {
      try {
        const response = (formGroup = await dispatch(
          updateFormGroup({ api, formGroup })
        ).unwrap());
        message.success("Group Updated");
        return response;
      } catch (error) {
        message.error("Error updating form group");
        console.error(error);
        return InitialFormGroupResponse;
      }
    },
    updateFormGroups: async (formPage: FormPageResponse) => {
      try {
        formPage.form_id = formResponse.form_id;
        const response = (formPage = await dispatch(
          updateFormGroups({
            api,
            formPage,
          })
        ).unwrap());
        message.success("Group Reordered");
        return response;
      } catch (error) {
        message.error("Error reordering form group");
        console.error(error);
        return InitialFormPageResponse;
      }
    },
    updateFormItemOrder: async (formItemsOrders: FormsGroupsItem[]) => {
      try {
        const response = await dispatch(
          updateFormItemOrder({ api, formItemsOrders })
        ).unwrap();
        message.success("Group Reordered");
        return response;
      } catch (error) {
        message.error("Error reordering form group");
        console.error(error);
        return [];
      }
    },
    updateFormPages: async (formResponse: AdminFormResponse) => {
      try {
        formResponse = await dispatch(
          updateFormPages({
            api,
            formResponse: formResponse,
          })
        ).unwrap();
        message.success("Page Reordered");
        return formResponse;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
        return InitialAdminFormResponse;
      }
    },
    updateFormPage: async (formPage: FormsPage) => {
      try {
        const response = await dispatch(
          updateFormPage({
            api,
            formPage,
          })
        ).unwrap();
        message.success("Page updated");
        return response;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
        return InitialFormPageResponse;
      }
    },
    deleteFormItem: async (formItemId: string) => {
      try {
        const response = await dispatch(
          deleteFormItem({
            api,
            formItemId: formItemId,
          })
        ).unwrap();

        message.success("Item Deleted");
        return response;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
        return "";
      }
    },
    updateFormItem: async (formItem: FormItemResponse) => {
      try {
        const res = await dispatch(
          updateFormItem({
            api,
            formItem,
          })
        ).unwrap();
        message.success("Item updated");
        return res;
      } catch (error) {
        message.error(
          `Failed to Update Form Item : ${getShortId(formItem.form_item_id)}`
        );
        console.error(error);
      }
      return InitialFormItemResponse;
    },
    updateFormSelectOptions: async (formOptions: FormsSelectOptions[]) => {
      try {
        const response = await dispatch(
          updateFormSelectOptions({ api, formSelectOptions: formOptions })
        ).unwrap();

        message.success("Form Select Options updated");
        return response;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
      }
      return [];
    },
    updateSingleFormSelectOption: async (formOption: FormsSelectOptions) => {
      try {
        const response = await dispatch(
          updateSingleFormSelectOptions({ api, option: formOption })
        ).unwrap();

        message.success("Form Select Option updated");

        return response;
      } catch (error) {
        message.error("Error updating form option");
        console.error(error);
      }
      return InitialFormsSelectOptions;
    },
    createSingleFormSelectOption: async (option: FormsSelectOptions) => {
      try {
        const response = await dispatch(
          createSingleFormSelectOptions({
            api,
            option,
          })
        ).unwrap();

        message.success("Form Select Option created");
        return response;
      } catch (error) {
        message.error("Error creating form option");
        console.error(error);
      }
      return InitialFormsSelectOptions;
    },
    transferFormGroup: async (transFormGroup: TransferFromGroup) => {
      try {
        const response = await dispatch(
          transferFormGroup({
            api,
            transferFormGroup: transFormGroup,
          })
        ).unwrap();
        message.success("Group Reordered");
        return response;
      } catch (error) {
        message.error("Error creating form item");
        console.error(error);
        return InitialTransferFormGroup;
      }
    },
    deleteFormGroup: async (formGroupId: string, formPageId: string) => {
      try {
        const value = await dispatch(
          deleteFormGroup({
            api,
            formGroupId,
          })
        ).unwrap();

        const formPage = formResponse.pages.find(
          (page) => page.form_page_id === formPageId
        );

        if (!formPage) {
          message.warning("Group Deleted, but not reordered");
          return value;
        }

        const updatedFormPage: FormPageResponse = {
          ...formPage,
          groups:
            formPage?.groups
              .filter((group) => group.form_group_id !== formGroupId)
              .map((group, group_order) => ({
                ...group,
                group_order,
              })) ?? [],
        };
        await dispatch(updateFormGroups({ api, formPage: updatedFormPage }));
        message.success("Group Deleted");
        return value;
      } catch (error) {
        message.error("Error deleting form group");
        console.error(error);
      }
      return "";
    },
    deleteFormPage: async (formPageId: string) => {
      try {
        const value = await dispatch(
          deleteFormPage({
            api,
            formPageId,
          })
        ).unwrap();

        const pages = formResponse.pages
          .filter((page) => page.form_page_id !== formPageId)
          .map((page, page_number) => ({
            ...page,
            page_number,
          }));
        const updatedResponse: AdminFormResponse = {
          ...formResponse,
          pages,
        };
        await dispatch(updateFormPages({ api, formResponse: updatedResponse }));
        message.success("Page Deleted");
        return value;
      } catch (error) {
        message.error("Error deleting form page");
        console.error(error);
      }
      return "";
    },
  };

  const openGrant = (grant: GrantPreview) => {
    window.open(`/grants/${grant.grant_id}`, "_blank");
  };
  if (!formResponse.form_id) return <></>;

  return (
    <div>
      <FormBuilderHeader
        formResponse={formResponse}
        functions={formBuilderFunctions}
        handleLocale={handleLocale}
      />
      <FormBuilder
        formResponse={formResponse}
        functions={formBuilderFunctions}
        form_id={formResponse.form_id}
        locale={selectedLocale}
        openGrant={openGrant}
      />
    </div>
  );
};

export default EditFormBuilder;

