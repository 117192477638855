import { IconProps } from "../features/entities/general";

const RestartProject = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1238_2082)">
          <path
            d="M30.2222 16C30.2222 17.8677 29.8543 19.7171 29.1396 21.4426C28.4249 23.1681 27.3773 24.736 26.0566 26.0566C24.736 27.3773 23.1681 28.4249 21.4426 29.1396C19.7171 29.8543 17.8677 30.2222 16 30.2222C14.1323 30.2222 12.2829 29.8543 10.5574 29.1396C8.83187 28.4249 7.26402 27.3773 5.94336 26.0566C4.62271 24.736 3.57511 23.1681 2.86037 21.4426C2.14564 19.7171 1.77777 17.8677 1.77777 16C1.77777 12.228 3.27618 8.61055 5.94336 5.94336C8.61055 3.27618 12.228 1.77777 16 1.77777C19.772 1.77777 23.3894 3.27618 26.0566 5.94336C28.7238 8.61055 30.2222 12.228 30.2222 16Z"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.1788 15.4817C22.2713 15.533 22.3484 15.6081 22.4021 15.6992C22.4558 15.7904 22.4841 15.8942 22.4841 16C22.4841 16.1058 22.4558 16.2096 22.4021 16.3008C22.3484 16.3919 22.2713 16.467 22.1788 16.5183L13.3246 21.4376C13.2344 21.4877 13.1327 21.5134 13.0295 21.512C12.9263 21.5107 12.8253 21.4825 12.7363 21.4301C12.6474 21.3778 12.5737 21.3031 12.5225 21.2135C12.4713 21.1239 12.4444 21.0225 12.4444 20.9193V11.0807C12.4444 10.6287 12.9296 10.3443 13.3246 10.5639L22.1788 15.4817Z"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1238_2082">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default RestartProject;
