import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GenderSelect from "@hellodarwin/core/lib/components/forms/form-groups/gender-select";
import {
  AdminProfile,
  AdminRoles,
} from "@hellodarwin/core/lib/features/entities";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import HubspotUserButton from "../../components/admins/hubspot-user-buttons";
import { updateAdmin } from "../../features/api/slices/admins-slice";
import { selectAuthentifiedUser } from "../../features/api/slices/global-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";

const AdminSettingsForm = ({
  admin,
}: {
  admin: AdminProfile;
  noSettings?: boolean;
}) => {
  const api = useAdminApi();
  const [form] = Form.useForm<AdminProfile>();
  const dispatch = useAppDispatch();
  const authenticatedAdmin = useAppSelector(selectAuthentifiedUser);

  const onFinish = (values: AdminProfile) => {
    try {
      const newAdmin: AdminProfile = {
        ...admin,
        ...values,
      };

      dispatch(updateAdmin({ api, data: newAdmin })).then((payload) =>
        payload.meta.requestStatus === "fulfilled"
          ? notification.success({ message: "Saved!", placement: "bottomLeft" })
          : notification.error({
              message: "Error while saving...",
              placement: "bottomLeft",
            })
      );
    } catch (error) {}
  };

  useEffect(() => {
    form.setFieldsValue(admin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  if (!admin) return <></>;

  const isOnHubspot = !!admin.hubspot?.hubspot_id;
  return (
    <Div flex="column" gap={16}>
      <Div flex="row" gap={16} align="center">
        <Typography elementTheme="body2" style={{ maxWidth: 350 }}>
          Hubspot Status :
        </Typography>
        <HubspotUserButton admin={admin} />
      </Div>
      {isOnHubspot && (
        <Typography
          elementTheme="body3"
          color={theme.colors.error}
          style={{ maxWidth: 350 }}
        >
          This account is linked to Hubspot. Please update {admin.hubspot?.name}
          's informations directly on Hubspot. It may take 24h for changes to be
          seen.
        </Typography>
      )}
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        initialValues={admin}
        layout="vertical"
        form={form}
      >
        <Form.Item label="First Name" name="first_name">
          <Input disabled={isOnHubspot} />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input disabled={isOnHubspot} />
        </Form.Item>
        <GenderSelect />
        <Form.Item label="Phone" name="phone">
          <Input disabled={isOnHubspot} />
        </Form.Item>
        <Form.Item label="Title" name="title">
          <Input disabled={isOnHubspot} />
        </Form.Item>
        <Form.Item label="Meeting Link (English)" name="meeting_link_en">
          <Input />
        </Form.Item>
        <Form.Item label="Meeting Link (French)" name="meeting_link_fr">
          <Input />
        </Form.Item>
        <Form.Item
          label="Language"
          name="preferred_language"
          rules={[
            {
              required: true,
              message: "Please select a preferred language",
            },
          ]}
        >
          <Select placeholder="Select option" disabled={isOnHubspot}>
            <Select.Option value="fr">French</Select.Option>
            <Select.Option value="en">English</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Role" name="roles">
          <Select
            placeholder="Select option"
            disabled={authenticatedAdmin.roles !== "SuperAdmin"}
          >
            {AdminRoles.map((role) => (
              <Select.Option key={role} value={role}>
                {role}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button htmlType="submit">Save</Button>
      </Form>{" "}
    </Div>
  );
};

export default AdminSettingsForm;

