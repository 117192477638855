import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  GrantsRefusalReason,
  Program,
  ProgramGrantStatus,
  ProgramType,
} from "@hellodarwin/core/lib/features/entities";
import { isDev } from "@hellodarwin/core/lib/features/helpers";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Dropdown from "antd/es/dropdown";
import Form from "antd/es/form";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import { MenuProps } from "antd/es/menu";
import message from "antd/es/message";
import Row from "antd/es/row";
import Select from "antd/es/select";
import dayjs from "dayjs";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "../../app";
import { selectAllAdmins } from "../../features/api/slices/admins-slice";
import { AdminPagesForms } from "../../pages/single-project-page";
import SelectDealModal from "./select-deal-modal";

const DatePicker = lazy(() => import("antd/es/date-picker"));

type ProgramFormProps = {
  program?: Program;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
};

export type ProgramFormValues = {
  program_name: string;
  program_account_manager: string;
  program_price: number;
  program_included_milestones: number;
  program_grant_id: string;
  program_grant_status: ProgramGrantStatus;
  program_requested_at?: any;
  program_targeted_amount: number;
  program_application_amount: number;
  program_applied_at?: any;
  program_accepted_amount: number;
  program_result_at?: any;
  program_refusal_reason?: GrantsRefusalReason;
  program_type: ProgramType;
};

const ProgramForm = ({ program, setForms }: ProgramFormProps) => {
  const [form] = Form.useForm<ProgramFormValues>();
  const [visible, setVisible] = useState(false);

  const admins = useAppSelector(selectAllAdmins);

  const initialValues: ProgramFormValues = useMemo(() => {
    return {
      program_account_manager: program?.program_account_manager || "",
      program_price: program?.program_price || 0,
      program_included_milestones: program?.program_included_milestones || 0,
      program_name: program?.program_name || "",
      program_grant_id: program?.program_grant_id || "",
      program_grant_status:
        program?.program_grant_status ||
        ProgramGrantStatus.IdentifiedOpportunities,
      program_requested_at: program?.program_requested_at
        ? dayjs(program?.program_requested_at)
        : undefined,
      program_targeted_amount: program?.program_targeted_amount || 0,
      program_application_amount: program?.program_application_amount || 0,
      program_applied_at: program?.program_applied_at
        ? dayjs(program?.program_applied_at)
        : undefined,
      program_accepted_amount: program?.program_accepted_amount || 0,
      program_result_at: program?.program_result_at
        ? dayjs(program?.program_result_at)
        : undefined,
      program_refusal_reason: program?.program_refusal_reason || undefined,
      program_type: program?.program_type || ProgramType.GrantsRoadmap,
    };
  }, [program]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    (async () => {
      try {
        if (!program || !program.program_id) {
          return;
        }

        setForms((prevState: AdminPagesForms) => ({
          ...prevState,
          programForm: form,
        }));
      } catch (e: any) {
        message.error("Something went wrong, try again later!");
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program?.program_id]);

  const actionMenuItems: MenuProps["items"] = [];

  const adminOptions = admins.map((admin) => (
    <Select.Option key={admin.admin_id} value={admin.admin_id}>
      {admin.first_name} {admin.last_name}
    </Select.Option>
  ));

  if (!program?.program_id) return <></>;

  return (
    <>
      <Card style={{ padding: "1rem" }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="program"
        >
          <Div flex="column" gap={8}>
            <Div flex="row" justify="space-between" align="center">
              <Typography.Title level={3}>Program</Typography.Title>
              {isDev && !!actionMenuItems.length && (
                <Dropdown menu={{ items: actionMenuItems }}>
                  <Button type="link">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              )}
            </Div>
            <Form.Item label="Program Name" name="program_name">
              <Input></Input>
            </Form.Item>
            <Form.Item label="Program Manager" name="program_account_manager">
              <Select showSearch>{adminOptions}</Select>
            </Form.Item>

            <Form.Item label="Grant ID" name="program_grant_id">
              <Input />
            </Form.Item>
            <Form.Item label="Grant Status" name="program_grant_status">
              <Select placeholder="Select program grant status">
                {Object.entries(ProgramGrantStatus).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
              <Suspense fallback={<></>}>
                <Form.Item
                  label="Requested At"
                  name="program_requested_at"
                  style={{ width: "50%" }}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%" }}
                    picker="date"
                    allowClear
                    placeholder="Requested date"
                  />
                </Form.Item>
              </Suspense>
              <Form.Item
                label="Targeted amount"
                name="program_targeted_amount"
                style={{ width: "45%" }}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Row>
            <Row gutter={8} justify={"space-between"} align={"middle"}>
              <Suspense fallback={<></>}>
                <Form.Item
                  label="Applied at"
                  name="program_applied_at"
                  style={{ width: "50%" }}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%" }}
                    picker="date"
                    allowClear
                    placeholder="Applied date"
                  />
                </Form.Item>
              </Suspense>
              <Form.Item
                label="Application amount"
                name="program_application_amount"
                style={{ width: "45%" }}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Row>
            <Row gutter={16} justify={"space-between"} align={"middle"}>
              <Suspense fallback={<></>}>
                <Form.Item
                  label="Result at"
                  name="program_result_at"
                  style={{ width: "50%" }}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%" }}
                    picker="date"
                    allowClear
                    placeholder="Result date"
                  />
                </Form.Item>
              </Suspense>
              <Form.Item
                label="Accepted Amount"
                name="program_accepted_amount"
                style={{ width: "45%" }}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Row>

            <Form.Item label="Refusal reason" name="program_refusal_reason">
              <Select placeholder="Select grants refusal reason">
                {Object.entries(GrantsRefusalReason).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Program Type" name="program_type">
              <Select placeholder="Select program type">
                {Object.entries(ProgramType).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {key.replace(/([A-Z])/g, " $1").trim()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Div>
        </Form>
      </Card>
      <SelectDealModal
        program={program}
        companyId={program.company?.company_id}
        visible={visible}
        handleClose={() => {
          form.setFieldValue(
            "program_grant_status",
            program.program_grant_status
          );
          setVisible(false);
        }}
      />
    </>
  );
};

export default ProgramForm;

