import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  AdminGrantResult,
  GrantTimeline,
} from "@hellodarwin/core/lib/features/entities";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Button from "antd/es/button";
import { default as Form } from "antd/es/form";
import { Row } from "antd/es/grid";
import message from "antd/lib/message";
import Skeleton from "antd/lib/skeleton";
import dayjs from "dayjs";
import { Suspense, lazy, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  generateGrantTimelines,
  selectIsLoadingGen,
  selectIsLoadingSoloGen,
} from "../../../features/api/slices/grants-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import theme from "../../../theme";

const DatePicker = lazy(() => import("antd/es/date-picker"));

export const grantTimelineSectionTitle = "grant_timeline";
type GrantFormTimelineProps = {
  grant?: AdminGrantResult;
  form: any;
  isGeneratingAll?: boolean;
  isGeneratingRemaining?: boolean;
};

const GrantFormTimeline = ({
  grant,
  form,
  isGeneratingAll,
  isGeneratingRemaining,
}: GrantFormTimelineProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [grantsTimeline, setGrantsTimeline] = useState<GrantTimeline[]>(
    grant?.grant_timeline ?? []
  );
  const isLoadingAny = useAppSelector((state) => selectIsLoadingGen(state));
  const isLoadingTimeline = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, grantTimelineSectionTitle)
  );

  useEffect(() => {
    if (
      isGeneratingAll ||
      !grantsTimeline ||
      (isGeneratingRemaining && grantsTimeline.length <= 0)
    ) {
      handleGenerateTimeline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingAll, isGeneratingRemaining]);

  const handleAddDate = () => {
    var newDates: GrantTimeline = {
      grant_timeline_id: "",
      grant_id: grant?.grant_id!,
      opened_at: new Date(),
      closed_at: new Date(),
    };
    if (!!grantsTimeline) {
      setGrantsTimeline([...grantsTimeline, newDates]);
    } else {
      setGrantsTimeline([newDates]);
    }
  };

  const accessScrapeContext = () => {
    window.open(`/grants/scrape-context/${grant?.grant_id}`, "_blank");
  };

  const handleGenerateTimeline = async () => {
    try {
      const generatedGrantTimeline = await dispatch(
        generateGrantTimelines({ api: api, grantId: grant?.grant_id! })
      ).unwrap();
      if (generatedGrantTimeline != null) {
        setGrantsTimeline(generatedGrantTimeline);
        form.setFieldValue("grant_timeline", generatedGrantTimeline);
        message.success("Grant Timeline Generated");
      } else {
        setGrantsTimeline([]);
        form.setFieldValue("grant_timeline", []);
        message.success("No timeline found");
      }
    } catch (e: any) {
      message.error("Something went wrong: ");
      message.error(e.cause);
      console.error(e);
    }
  };

  const handleRemoveDate = async (
    index: number,
    grantTimelineId: string | undefined
  ) => {
    const newGrantTimeline = grantsTimeline.filter((_, i) => i !== index);
    setGrantsTimeline(newGrantTimeline);
    form.setFieldValue("grant_timeline", newGrantTimeline);
    if (!!grantTimelineId)
      try {
        await api.deleteGrantTimeline(grantTimelineId);
      } catch (e: any) {
        console.error(e);
      }
  };

  const handleDateChange = (index: number, date: any, field: string) => {
    const newGrantTimeline = grantsTimeline.map((timeline, i) => {
      if (i === index) {
        return {
          ...timeline,
          [field]: date ? date.toDate() : null,
        };
      }
      return timeline;
    });
    setGrantsTimeline(newGrantTimeline);
    form.setFieldValue("grant_timeline", newGrantTimeline);
  };

  const displayGrantTimelines = () => {
    return (
      !!grantsTimeline &&
      grantsTimeline.map((timeline, index) => (
        <>
          <Row align={"bottom"} style={{ marginBottom: 32 }} key={index}>
            <Button
              onClick={() =>
                handleRemoveDate(index, timeline.grant_timeline_id)
              }
              type="text"
              size="small"
              danger={true}
              disabled={isLoadingTimeline}
              icon={<Delete width={24} height={24} />}
              style={{ marginBottom: 8 }}
            />
            <Div
              flex="column"
              fitMaxContent
              style={{ marginLeft: 16, marginRight: 16 }}
            >
              <Typography>Opened At</Typography>
              <Suspense fallback={<Loading />} key={index}>
                {!isLoadingTimeline ? (
                  <DatePicker
                    size="large"
                    picker="date"
                    allowClear
                    value={
                      timeline.opened_at ? dayjs(timeline.opened_at) : null
                    }
                    onChange={(date) =>
                      handleDateChange(index, date, "opened_at")
                    }
                  />
                ) : (
                  <Skeleton
                    style={{ width: 150, height: 32 }}
                    paragraph={{ rows: 0, width: "100%" }}
                  />
                )}
              </Suspense>
            </Div>
            <Div flex="column" fitMaxContent>
              <Typography>Closed At</Typography>
              <Suspense fallback={<Loading />} key={index}>
                {!isLoadingTimeline ? (
                  <DatePicker
                    size="large"
                    picker="date"
                    allowClear
                    value={
                      timeline.closed_at ? dayjs(timeline.closed_at) : null
                    }
                    onChange={(date) =>
                      handleDateChange(index, date, "closed_at")
                    }
                  />
                ) : (
                  <Skeleton style={{ width: 150 }} paragraph={{ rows: 0 }} />
                )}
              </Suspense>
            </Div>
          </Row>
        </>
      ))
    );
  };

  return (
    <>
      <Row gutter={12} justify="space-between" style={{ marginBottom: "32px" }}>
        <Button
          color={theme.colors.purple_1}
          onClick={() => {
            handleGenerateTimeline();
          }}
          size="middle"
          type="primary"
          style={{ width: "200px" }}
          loading={isLoadingAny}
          disabled={isLoadingAny}
        >
          Generate Timeline
        </Button>
        <Button
          color={theme.colors.purple_1}
          type="default"
          onClick={accessScrapeContext}
        >
          See scrape context
        </Button>
      </Row>
      <Form.Item name={"grant_timeline"}>
        {displayGrantTimelines()}
        <Button
          onClick={() => handleAddDate()}
          type="text"
          size="middle"
          icon={<AddNew width={24} height={24} />}
        />
      </Form.Item>
    </>
  );
};

export default GrantFormTimeline;

