import {
  FormItemResponse,
  FormsSelectOptions,
} from "@hellodarwin/core/lib/features/entities/form-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import Checkbox from "antd/es/checkbox";
import DatePicker from "antd/es/date-picker";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import Radio, { RadioChangeEvent } from "antd/es/radio";
import Select, { DefaultOptionType } from "antd/es/select";
import Space from "antd/es/space";
import React, { ChangeEvent, CSSProperties, SetStateAction } from "react";

interface FormSubmissionEditInputFieldProps {
  formItem: FormItemResponse;
  setCurrentValue: React.Dispatch<SetStateAction<string>>;
  value: string;
  style: CSSProperties;
}

export const getSelectOptions = (
  formsSelectOptions: FormsSelectOptions[],
  locale: string
): DefaultOptionType[] => {
  return formsSelectOptions.map((option) => ({
    label: locale === "en" ? option.label_en : option.label_fr,
    value: option.value,
  }));
};

const FormSubmissionEditInputField = ({
  formItem,
  value,
  setCurrentValue,
  style,
}: FormSubmissionEditInputFieldProps) => {
  const { selectedLocale } = useLocale();

  const options = formItem.form_select_options
    ? getSelectOptions(formItem.form_select_options, selectedLocale)
    : [];

  const handleInputChange = (e: ChangeEvent<any>) => {
    setCurrentValue(e.target.value);
  };
  const handleRadioChange = (e: RadioChangeEvent) => {
    setCurrentValue(e.target.value);
  };
  const handleCheckboxChange = (values: string[]) => {
    setCurrentValue(values.join(","));
  };

  return (
    <div style={style}>
      {formItem.type === "string" ? (
        formItem.field_type === "text" ? (
          <TextArea value={value} onChange={handleInputChange} />
        ) : (
          <Input value={value} onChange={handleInputChange} />
        )
      ) : formItem.type === "datetime" ? (
        formItem.field_type === "date" ? (
          <DatePicker picker="date" onChange={handleInputChange} />
        ) : (
          <DatePicker picker="time" onChange={handleInputChange} />
        )
      ) : formItem.type === "enum" ? (
        formItem.field_type === "select" ? (
          <Select
            style={style}
            onChange={(value) => setCurrentValue(value)}
            options={options}
            value={value}
          />
        ) : formItem.field_type === "radio" ? (
          <Radio.Group onChange={handleRadioChange} value={value}>
            <Space direction={"vertical"}>
              {React.Children.toArray(
                options.map((option) => (
                  <Radio value={option.value}>{option.label}</Radio>
                ))
              )}
            </Space>
          </Radio.Group>
        ) : formItem.field_type === "checkbox" ? (
          <Checkbox.Group
            onChange={handleCheckboxChange}
            value={value.split(",")}
          >
            <Space direction={"vertical"}>
              {React.Children.toArray(
                options.map((option) => (
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                ))
              )}
            </Space>
          </Checkbox.Group>
        ) : (
          <></>
        )
      ) : formItem.type === "file" ? (
        formItem.field_type === "attachment" ? (
          <Input type="file" onChange={handleInputChange} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
export default FormSubmissionEditInputField;

