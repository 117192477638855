import { IconProps } from "../features/entities/general";

const AddPage = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.8997 19.0493V15.1009C26.8997 13.7546 26.3806 12.4635 25.4565 11.5115C24.5324 10.5595 23.2791 10.0246 21.9723 10.0246H19.7823C19.3467 10.0246 18.9289 9.84637 18.6209 9.529C18.3129 9.21166 18.1398 8.78126 18.1398 8.3325V6.07634C18.1398 4.73001 17.6207 3.43882 16.6966 2.48683C15.7725 1.53482 14.5192 1 13.2124 1H10.4749M13.7599 1H6.64248C5.73583 1 5 1.75806 5 2.69211V28.6378C5 29.5719 5.73583 30.33 6.64248 30.33H25.2573C26.1639 30.33 26.8997 29.5719 26.8997 28.6378V14.5369C26.8997 10.9467 25.5154 7.50353 23.0511 4.96486C20.5869 2.4262 17.2448 1 13.7599 1Z"
          stroke={color}
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 14V24M21 19H11"
          stroke={color}
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default AddPage;

