import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Drawer from "antd/es/drawer";
import Form from "antd/es/form";
import useForm from "antd/es/form/hooks/useForm";
import Input from "antd/es/input/Input";
import Radio from "antd/es/radio";
import Select from "antd/es/select";
import { useEffect, useState } from "react";
import {
  FormItemResponse,
  FormsConditionTypeList,
  FormsSelectOptions,
  InitialFormsSelectOptions,
} from "../../../../../features/entities/form-entities";
import { getShortId } from "../../../../../features/helpers";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Clipboard from "../../../../common/clipboard";
import Div from "../../../../common/div";
import RichTextEditor from "../../../../common/rich-editor";
import Typography from "../../../../common/typography";
import FormItem from "../../../form-layouts/form-item";
import FormLabel from "../../../form-layouts/form-label";
import { FormBuilderItemEditButtonProps } from "../../types";
import { FormItemOptions } from "../../utils/form-items-type";
import getItemTypeFromItemFieldType from "../../utils/get-item-type-from-item-field-type";
import FormItemEditList from "./form-edit-list";

const FormBuilderItemEditButton = ({
  itemEntity,
  pages,
  functions,
}: FormBuilderItemEditButtonProps) => {
  const theme = useTheme();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModal = () => setEditModalOpen(!editModalOpen);
  const [fieldType, setFieldType] = useState(itemEntity.field_type);
  const [form] = useForm<FormItemResponse>();

  const [activeOption, setActiveOption] =
    useState<FormsSelectOptions>(undefined);

  const handleSubmit = () => {
    const values = form.getFieldsValue();

    const updatedItem: FormItemResponse = {
      ...itemEntity,
      ...values,
      type: getItemTypeFromItemFieldType(
        values.field_type ?? itemEntity.field_type
      ),
    };

    functions.updateFormItem(updatedItem);
  };

  const reorderOptions = (newOptions: FormsSelectOptions[]) => {
    for (let index = 0; index < newOptions.length; index++) {
      newOptions[index] = { ...newOptions[index], display_order: index };
    }
    functions.updateFormSelectOptions(newOptions);
  };

  useEffect(() => {
    setFieldType(itemEntity.field_type);
  }, [itemEntity]);

  const addNewOption = () => {
    setActiveOption({
      ...InitialFormsSelectOptions,
      form_item_id: itemEntity.form_item_id,
      display_order: itemEntity.form_select_options?.length ?? 0,
    });
  };

  const handleSubmitFormSelectOption = (option: FormsSelectOptions) => {
    if (!!option.form_select_option_id) {
      functions.updateSingleFormSelectOption(option);
    } else {
      functions.createSingleFormSelectOption(option);
    }
  };
  return (
    <>
      <Button
        size="square"
        trailingIcon={<Edit width={12} height={12} />}
        onClick={handleEditModal}
        defaultStyle={theme.colors.purple_3}
      />
      <Drawer
        open={editModalOpen}
        onClose={handleEditModal}
        title={
          <Div
            align="center"
            flex="row"
            style={{ height: "100%" }}
            justify="space-between"
          >
            <Div fitContent flex="row" gap={8} align="center">
              <Typography elementTheme="subtitle1" lineClamp={3}>
                Item :
              </Typography>
              <Typography
                elementTheme="subtitle1"
                color={theme.colors.purple_1}
                colorHover={theme.colors.purple_2}
              >
                <Clipboard
                  title={getShortId(itemEntity.form_item_id)}
                  copy={itemEntity.form_item_id}
                />
              </Typography>
            </Div>
            <Button
              size="circle"
              defaultStyle={theme.colors.white_1}
              onClick={handleSubmit}
            >
              <Save width={16} height={16} />
            </Button>
          </Div>
        }
      >
        <Form layout="vertical" initialValues={itemEntity} form={form}>
          <Div flex="column" gap={24}>
            <FormItem
              name={"forms_groups_items_order"}
              label={<FormLabel label="Item Order" />}
            >
              <Input disabled />
            </FormItem>
            <FormItem
              name={"field_type"}
              label={<FormLabel label="Field Type" />}
            >
              <Select options={FormItemOptions} onChange={setFieldType} />
            </FormItem>
            {["radio", "checkbox"].includes(fieldType) && (
              <FormItem
                name={"disposition"}
                label={<FormLabel label="Direction" />}
              >
                <Select
                  defaultValue={"horizontal"}
                  options={[
                    { value: "vertical", label: "Vertical" },
                    { value: "horizontal", label: "Horizontal" },
                  ]}
                />
              </FormItem>
            )}
            <FormItem name={"required"} label={<FormLabel label="Required" />}>
              <Radio.Group
                options={[
                  { label: "Yes", value: "required" },
                  { label: "No", value: "" },
                ]}
              />
            </FormItem>
            <FormItem
              name={"content_en"}
              label={<FormLabel label="Content En" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={"content_fr"}
              label={<FormLabel label="Content Fr" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={"heading_en"}
              label={<FormLabel label="Heading En" />}
            >
              <RichTextEditor initialValue={itemEntity.heading_en} />
            </FormItem>
            <FormItem
              name={"heading_fr"}
              label={<FormLabel label="Heading Fr" />}
            >
              <RichTextEditor initialValue={itemEntity.heading_fr} />
            </FormItem>
            {["radio", "select", "checkbox"].includes(
              itemEntity.field_type
            ) && (
              <FormItem
                name={"form_select_options"}
                label={
                  <FormLabel
                    label="Options"
                    actions={
                      <Button
                        transparent
                        defaultStyle={theme.colors.primary}
                        onClick={() => addNewOption()}
                      >
                        <AddNew width={16} height={16} />
                      </Button>
                    }
                  />
                }
              >
                <FormItemEditList<FormsSelectOptions>
                  options={itemEntity.form_select_options ?? []}
                  optionLabel={"label_en"}
                  optionTitleLabel={"form_select_option_id"}
                  reorder={reorderOptions}
                  update={handleSubmitFormSelectOption}
                  activeElement={activeOption}
                  setActiveElement={setActiveOption}
                  renderTitleLabel={(key) => (
                    <Typography
                      elementTheme="subtitle1"
                      color={theme.colors.purple_1}
                      colorHover={theme.colors.purple_2}
                    >
                      <Clipboard
                        title={getShortId(key as string)}
                        copy={key as string}
                      />
                    </Typography>
                  )}
                  typeLabel="Option"
                  inputs={[
                    {
                      label: "Display order",
                      name: "display_order",
                      children: <Input disabled />,
                    },
                    {
                      label: "Label En",
                      name: "label_en",
                      children: <Input />,
                    },
                    {
                      label: "Label Fr",
                      name: "label_fr",
                      children: <Input />,
                    },
                    {
                      label: "Value",
                      name: "value",
                      children: <Input />,
                    },

                    {
                      label: "Hidden",
                      name: "hidden",
                      children: (
                        <Radio.Group
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </FormItem>
            )}
            <FormItem
              name={"form_item_conditions"}
              label={<FormLabel label="Conditions" />}
            >
              <FormItemEditList
                options={itemEntity.form_item_conditions}
                optionLabel={"action_page"}
                optionTitleLabel={"form_condition_id"}
                renderLabel={(key) => ("Go to page " + key) as string}
                renderTitleLabel={(key) => (
                  <Typography
                    elementTheme="subtitle1"
                    color={theme.colors.purple_1}
                    colorHover={theme.colors.purple_2}
                  >
                    <Clipboard
                      title={getShortId(key as string)}
                      copy={key as string}
                    />
                  </Typography>
                )}
                typeLabel="Condition"
                inputs={[
                  {
                    label: "Condition Type",
                    name: "condition_type",
                    children: (
                      <Select
                        options={
                          !!FormsConditionTypeList &&
                          Object.keys(FormsConditionTypeList)?.map((value) => ({
                            label: FormsConditionTypeList[value],
                            value,
                          }))
                        }
                      />
                    ),
                  },
                  {
                    label: "Triggers",
                    name: "trigger_values",
                    children: <Input />,
                  },
                  {
                    label: "Action Page",
                    name: "action_page",
                    children: (
                      <Select
                        options={pages.map((page) => ({
                          label: page.entity.page_title_en,
                          value: page.entity.page_number,
                        }))}
                      />
                    ),
                  },
                ]}
              />
            </FormItem>
          </Div>
        </Form>
      </Drawer>
    </>
  );
};
export default FormBuilderItemEditButton;

