import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import ProgramSingle from "@hellodarwin/core/lib/components/programs/single";
import { Grant } from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import { RootState } from "../../app/app-store";
import {
  fetchAdminById,
  selectAdminById,
} from "../../features/api/slices/admins-slice";
import {
  fetchGrant,
  selectGrantById,
} from "../../features/api/slices/grants-slice";
import {
  fetchProgramById,
  selectProgramsId,
} from "../../features/api/slices/programs-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

const SingleCompanyProgramPage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const { companyId, grantId } = useParams<{
    companyId: string;
    grantId: string;
  }>();
  const program = useAppSelector((state: RootState) =>
    selectProgramsId(state, `${grantId}`)
  );
  const grant = useAppSelector((state: RootState) =>
    selectGrantById(state, `${program?.program_grant_id}`)
  );
  const admin = useAppSelector((state: RootState) =>
    selectAdminById(state, `${program?.program_account_manager}`)
  );

  useEffect(() => {
    if (!!grantId) {
      dispatch(fetchProgramById({ api, programId: grantId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!program) {
      dispatch(fetchGrant({ api, grantId: `${program?.program_grant_id}` }));
      dispatch(
        fetchAdminById({
          api,
          aminId: `${program?.program_account_manager}`,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const goBack = () => {
    navigate(`/companies/${companyId}/grants`);
  };

  const { selectedLocale } = useLocale();

  if (!program || !grant) return <Loading />;

  const queriedGrant = Object.entries(grant).reduce((o, entry) => {
    const [key, value] = entry;
    if (key.includes("en") || key.includes("fr")) {
      if (selectedLocale === "en" && key.includes("en")) {
        return { ...o, [key.replace("_en", "")]: value };
      } else if (selectedLocale === "fr" && key.includes("fr")) {
        return { ...o, [key.replace("_fr", "")]: value };
      } else {
        return { ...o };
      }
    } else {
      return { ...o, [key]: value };
    }
  }, {});
  return (
    <PageLayout
      handleBack={goBack}
      app="admin"
      title={program?.program_name}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Programs",
          path: "/programs",
        },
        {
          breadcrumbName: program?.program_name,
        },
      ]}
    >
      <ProgramSingle {...program} grant={queriedGrant as Grant} admin={admin} />
    </PageLayout>
  );
};

export default SingleCompanyProgramPage;

