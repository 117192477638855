import Button from "@hellodarwin/core/lib/components/common/button";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  GrantProvider,
  GrantProviders,
} from "@hellodarwin/core/lib/features/entities";
import Col from "antd/es/col";
import message from "antd/es/message";
import Row from "antd/es/row";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import GrantProviderForm from "../../components/grants/grant-provider-form";
import {
  fetchGrantProvider,
  selectGrantProviderById,
  updateGrantProvider,
} from "../../features/api/slices/grants-slice";
import { selectIsLoading } from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../single-project-page";

const SingleGrantProviderPage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{
    id: string;
  }>();
  const grantProvider = useAppSelector((state) =>
    selectGrantProviderById(state, params.id || "")
  );
  const loading = useAppSelector(selectIsLoading);
  const [forms, setForms] = useState<AdminPagesForms>({
    grantForm: undefined,
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(fetchGrantProvider({ api, grantProviderId: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!params.id) return <Loading />;

  if (loading || !grantProvider) {
    return <Loading />;
  }

  const onSave = async () => {
    try {
      const grantProviderFormValues =
        forms.grantProviderForm?.getFieldsValue()!;

      if (grantProviderFormValues === undefined) {
        return;
      }

      const newGrantProvider: GrantProvider[] = [
        {
          grant_provider_id: grantProvider[0].grant_provider_id,
          locale: "en",
          title: grantProviderFormValues.title_en,
          description: grantProviderFormValues.description_en,
          logo: grantProvider[0].logo,
          rank: grantProviderFormValues.rank,
          slug: grantProviderFormValues.slug_en,
          url: grantProviderFormValues.url_en,
          acronym: grantProviderFormValues.acronym_en,
        },
        {
          grant_provider_id: grantProvider[1].grant_provider_id,
          locale: "fr",
          title: grantProviderFormValues.title_fr,
          description: grantProviderFormValues.description_fr,
          logo: grantProvider[1].logo,
          rank: grantProviderFormValues.rank,
          slug: grantProviderFormValues.slug_fr,
          url: grantProviderFormValues.url_fr,
          acronym: grantProviderFormValues.acronym_fr,
        },
      ];

      const updatedGrantProvider: GrantProvider[] = {
        ...grantProvider,
        ...newGrantProvider,
      };

      const sendGrantProvider: GrantProviders = {
        grant_provider_en: updatedGrantProvider[0],
        grant_provider_fr: updatedGrantProvider[1],
      };

      await dispatch(
        updateGrantProvider({ api, grantProvider: sendGrantProvider })
      );

      message.success("Saved!");
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const goToGrantProviders = () => {
    navigate("/grants/providers");
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={grantProvider![0].title}
      title={grantProvider![0].title}
      handleBack={goToGrantProviders}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants",
          path: "/grants",
        },
        {
          breadcrumbName: "Providers",
          path: "/grants/providers",
        },
        {
          breadcrumbName: grantProvider?.[0]?.title,
        },
      ]}
    >
      <Button onClick={onSave} size="small">
        Save
      </Button>
      <Row gutter={24}>
        <Col span={24}>
          <GrantProviderForm
            grantProvider={grantProvider}
            setForms={setForms}
          ></GrantProviderForm>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default SingleGrantProviderPage;

