import { CSSProperties, PropsWithChildren } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import Column from "./columns";
import Drag from "./drag";
import Drop from "./drop";
import { addItem, deleteItem, onChange } from "./utils";

interface DragDropMultipleColumnContainerProps<T> {
  data: DragDropData<T>;
  setData: (data: DragDropData<T>) => void;
  style?: CSSProperties;
  itemStyle?: CSSProperties;
  direction?: "vertical" | "horizontal";
  onChange?: (newItems: T[]) => void;
}

export interface DragDropItem<T> extends PropsWithChildren {
  id: string;
  entity: T;
}
export interface DragDropColumn {
  id: string;
  title: string;
  itemIds: string[];
  isDropDisabled?: boolean;
}

export interface DragDropItems<T> {
  [key: string]: DragDropItem<T>;
}

export interface DragDropColumns {
  [key: string]: DragDropColumn;
}

export interface DragDropData<T> {
  items: DragDropItems<T>;
  columns: DragDropColumns;
  columnOrder: string[];
}

const DragDropMultipleColumnContainer = <T,>({
  data,
  setData,
  style,
  direction = "horizontal",
  itemStyle,
}: DragDropMultipleColumnContainerProps<T>) => {
  const onDragEnd = (res: DropResult) => {
    const { source, destination, draggableId } = res;
    if (!destination) return;
    if (onChange(source, destination)) return;
    
    if (res.type === "item") {
      let newData = deleteItem(data, source);
      newData = addItem(newData, destination, draggableId);

      setData(newData);
    }
    if (res.type === "column") {
      let columnOrder = [...data.columnOrder];
      columnOrder.splice(source.index, 1);
      columnOrder.splice(destination.index, 0, draggableId);
      data.columnOrder = columnOrder;
      setData({ ...data });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Drop
        className="column-content"
        droppableId="all-columns"
        type="column"
        direction={direction}
      >
        {data.columnOrder.map((columnId, index) => {
          const column = data.columns[columnId];
          const items = column.itemIds.map((itemId) => data.items[itemId]);

          return (
            <Drag
              key={columnId}
              draggableId={columnId}
              index={index}
              dragAll={false}
            >
              <Column column={column} items={items} itemStyle={itemStyle} />
            </Drag>
          );
        })}
      </Drop>
    </DragDropContext>
  );
};

export default DragDropMultipleColumnContainer;

