import { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  FormBuilderItem,
  ItemsTypes,
} from "../../../../../features/entities/form-entities";
import { getShortId } from "../../../../../features/helpers";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import { DragDropContainer } from "../../styles";
import { FormBuilderGroupProps } from "../../types";
import getItemTranslatedLabel from "../../utils/get-item-translated-label";
import getTypeComponent from "../../utils/get-type-component";
import FormBuilderGroupActions from "./form-builder-group-actions";
import FormItemContentButtons from "./form-builder-item-actions";

const FormBuilderGroup = ({
  builderGroup,
  pages,
  locale,
  functions,
  formPageId,
}: FormBuilderGroupProps) => {
  const theme = useTheme();
  const [formItems, setFormItems] = useState<{
    [key: string]: FormBuilderItem;
  }>(builderGroup?.items ?? {});

  useEffect(() => {
    setFormItems(builderGroup?.items ?? {});
  }, [builderGroup?.items]);

  const direction = builderGroup.entity.group_disposition ?? "horizontal";
  return (
    <Div flex="column" gap={16}>
      <Div flex="row" align="center" justify="space-between">
        <Typography elementTheme="subtitle2">
          {getItemTranslatedLabel({
            label_en: builderGroup?.entity.group_title_en,
            label_fr: builderGroup?.entity.group_title_fr,
            fallback: `${getShortId(builderGroup?.entity.form_group_id)}`,
            locale,
          })}
        </Typography>

        <FormBuilderGroupActions
          formPageId={formPageId}
          formGroupId={builderGroup?.entity.form_group_id}
          builderGroup={builderGroup}
          functions={functions}
        />
      </Div>
      <DragDropContainer $isDroppable>
        <Droppable
          droppableId={`group_droppable_${builderGroup.id}`}
          type={ItemsTypes.items}
          direction={direction}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "flex",
                flexDirection: direction === "horizontal" ? "row" : "column",
                gap: direction === "horizontal" ? 32 : 8,
                minHeight: 50,
              }}
            >
              {Object.values(formItems)
                .sort(
                  (itm1, itm2) =>
                    itm1?.entity.forms_groups_items_order -
                    itm2?.entity.forms_groups_items_order
                )
                .map(({ category, entity }, index) => (
                  <Draggable
                    key={`form_item_draggable_${entity?.form_item_id}`}
                    draggableId={`form_item_draggable_${entity?.form_item_id}`}
                    index={index}
                  >
                    {(provided) => {
                      const Component = getTypeComponent(category);
                      return (
                        <DragDropContainer
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Div
                            flex="row"
                            align="center"
                            justify="space-between"
                            gap={24}
                            style={{
                              padding: "12px 0",
                              borderTop: `1px dashed ${theme.colors.grey_2}`,
                              borderBottom: `1px dashed ${theme.colors.grey_2}`,
                              cursor: "pointer",
                            }}
                          >
                            <Component
                              entity={entity}
                              type={entity.field_type as any}
                              isSimple
                              locale={locale}
                            />
                            <FormItemContentButtons
                              formItemId={entity.form_item_id}
                              itemEntity={entity}
                              pages={pages}
                              functions={functions}
                            />
                          </Div>
                        </DragDropContainer>
                      );
                    }}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContainer>
    </Div>
  );
};

export default FormBuilderGroup;

