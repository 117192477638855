import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Milestone, Program } from "@hellodarwin/core/lib/features/entities";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import { MenuProps } from "antd/es/menu";
import Modal from "antd/es/modal";
import { useAppDispatch, useAppSelector } from "../../app";
import { selectAuthentifiedUser } from "../../features/api/slices/global-slice";
import {
  createMilestone,
  deleteProgram,
} from "../../features/api/slices/milestones-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type ProgramActionsProps = {
  program: Program;
};

const ProgramActions = ({ program }: ProgramActionsProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const admin = useAppSelector(selectAuthentifiedUser);

  const handleDeleteProgram = () => {
    Modal.confirm({
      title: "Delete Program",
      content: "Are you sure you want to delete this program",
      async onOk() {
        dispatch(
          deleteProgram({
            api: api,
            programId: program.program_id,
          })
        );
      },
      onCancel() {},
    });
  };

  const handleCreateMilestone = () => {
    dispatch(
      createMilestone({
        api: api,
        milestone: {
          milestone_caller: `${admin?.admin_id}`,
          milestone_program_id: program.program_id,
        } as Milestone,
      })
    );
  };

  const actionMenuItems: MenuProps["items"] = [
    {
      label: "Delete program",
      onClick: handleDeleteProgram,
      key: 0,
    },
    {
      label: "Create milestone",
      onClick: handleCreateMilestone,
      key: 1,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items: actionMenuItems }}>
        <Button type="link">
          <Typography elementTheme="subtitle1">
            <EllipsisOutlined />
          </Typography>
        </Button>
      </Dropdown>
    </>
  );
};

export default ProgramActions;

