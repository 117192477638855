import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  FormSection,
  FormsSubmissionItem,
} from "@hellodarwin/core/lib/features/entities/form-entities";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Save from "@hellodarwin/icons/dist/icons/Save";
import { useEffect, useState } from "react";
import FormSubmissionEditInputField from "./form-submission-edit-input-field";

interface FormSubmissionEditInputProps {
  handleSave: (formSubmissionItem: FormsSubmissionItem, value: string) => void;
  formSection: FormSection;
}
const FormSubmissionEditInput = ({
  handleSave,
  formSection,
}: FormSubmissionEditInputProps) => {
  const theme = useTheme();

  const [currentValue, setCurrentValue] = useState<string>(
    formSection.forms_submissions_item.value
  );
  const [isEdit, setEdit] = useState(false);
  const handleIsEdit = () => setEdit(!isEdit);

  const handleFinish = () => {
    handleSave(formSection.forms_submissions_item, currentValue);
    setEdit(false);
  };

  const resetValue = () => {
    setCurrentValue(formSection.forms_submissions_item.value);
  };
  const handleClose = () => {
    resetValue();
    setEdit(false);
  };

  useEffect(() => {
    resetValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSection]);

  return isEdit ? (
    <Div flex="row" gap={54} align="center" justify="space-between" fitContent>
      <FormSubmissionEditInputField
        formItem={formSection.form_item}
        style={{ width: 200, fontSize: 16, lineHeight: "28px" }}
        setCurrentValue={setCurrentValue}
        value={currentValue}
      />

      <Div flex="row" gap={8} align="center" fitContent>
        <Button
          headingIcon={<CancelProject width={16} height={16} />}
          size="circle"
          onClick={handleClose}
          defaultStyle={theme.colors.white_1}
        />
        <Button
          headingIcon={<Save width={16} height={16} />}
          size="circle"
          onClick={handleFinish}
          defaultStyle={theme.colors.primary}
        />
      </Div>
    </Div>
  ) : (
    <Div flex="row" gap={54} align="center" justify="space-between" fitContent>
      <Typography
        style={{
          width: 200,
          padding: "4px 11px",
          height: 35,
          border: "1px solid transparent",
        }}
      >
        {currentValue}
      </Typography>
      <Button
        headingIcon={<Edit width={16} height={16} />}
        size="circle"
        onClick={handleIsEdit}
        defaultStyle={theme.colors.primary}
      />
    </Div>
  );
};

export default FormSubmissionEditInput;

