import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { ProjectDescriptionTemplate } from "@hellodarwin/core/lib/features/entities/project-entities";
import Language from "@hellodarwin/core/lib/features/enums/language";
import message from "antd/es/message";
import Select from "antd/es/select";
import { useEffect, useState } from "react";
import ProjectDescriptionTemplateForm from "../../components/projects/project-description-template-form";
import { useAdminApi } from "../../features/api/use-admin-api";

const ProjectDescriptionTemplateContainer = () => {
  const api = useAdminApi();

  const [projectDescriptionTemplates, setProjectDescriptionTemplates] =
    useState<ProjectDescriptionTemplate>({} as ProjectDescriptionTemplate);
  const [language, setLanguage] = useState<string>("en");

  useEffect(() => {
    fetchProjectDescriptionTemplates(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const fetchProjectDescriptionTemplates = async (language: string) => {
    try {
      const templates =
        await api.fetchProjectDescriptionTemplateByLanguage(language);
      setProjectDescriptionTemplates(templates);
    } catch (error: any) {
      console.error(error);
      message.error("Failed to fetch project description templates");
    }
  };

  const handleLanguageChange = (selectedLanguage: string) => {
    setLanguage(selectedLanguage);
  };

  return (
    <Div flex="row" gap={32} align="center">
      <Div flex="row" gap={32} align="center">
        <Typography.Title level={5}>
          Project Description Template
        </Typography.Title>
        <Select
          style={{ marginBottom: "0.80%" }}
          value={language}
          onChange={handleLanguageChange}
        >
          <Select.Option value={Language.English}>English</Select.Option>
          <Select.Option value={Language.French}>Français</Select.Option>
        </Select>
      </Div>
      <ProjectDescriptionTemplateForm
        key={projectDescriptionTemplates.project_description_template_id}
        projectDescriptionTemplate={projectDescriptionTemplates}
        language={language}
      />
    </Div>
  );
};

export default ProjectDescriptionTemplateContainer;

