import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Layout from "antd/es/layout";
import { MenuProps } from "antd/es/menu";
import { useAppDispatch } from "../app";
import { toggleProgramsModal } from "../features/api/slices/programs-slice";
import { toggleProjectsModal } from "../features/api/slices/projects-slice";
import { toggleProviderModal } from "../features/api/slices/providers-slice";
import HeaderBarUser from "./header-bar-user";

import theme from "@hellodarwin/core/lib/theme";
import LanguageSwitcher from "./language-switcher";
import SearchBar from "./search-bar";
const { Header } = Layout;
const HeaderBar = () => {
  const dispatch = useAppDispatch();
  const toggleProjectForm = () => {
    dispatch(toggleProjectsModal({ type: "create", isVisible: true }));
  };

  const toggleProviderForm = () => {
    dispatch(toggleProviderModal({ type: "create", isVisible: true }));
  };

  const toggleProgramForm = () => {
    dispatch(toggleProgramsModal({ type: "create", isVisible: true }));
  };

  const actionMenu: MenuProps["items"] = [
    { label: "Create Project", key: 1, onClick: toggleProjectForm },
    { label: "Create Provider", key: 1, onClick: toggleProviderForm },
    { label: "Create Program", key: 1, onClick: toggleProgramForm },
  ];

  return (
    <Header style={{ padding: 0, height: "auto" }}>
      <Div
        flex="row"
        style={{
          width: "100%",
          backgroundColor: theme.colors.white_1,
          height: 44,
        }}
      >
        <SearchBar />
        <Div
          flex="row"
          align="center"
          justify="end"
          style={{ marginRight: "1rem" }}
        >
          <LanguageSwitcher />
          <Dropdown menu={{ items: actionMenu }}>
            <Button type="link">
              <EllipsisOutlined />
            </Button>
          </Dropdown>

          <HeaderBarUser />
        </Div>
      </Div>
    </Header>
  );
};

export default HeaderBar;

