import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProfileEditModal, {
  ProfileEditModalFunctions,
} from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal";
import Loading from "@hellodarwin/core/lib/components/loading";
import ProviderProfile from "@hellodarwin/core/lib/components/provider/profile";
import ContactPreview from "@hellodarwin/core/lib/components/provider/profile/profile-contact/contact-preview";
import {
  AdminSelectedTags,
  ProfileModalProps,
  Provider,
} from "@hellodarwin/core/lib/features/entities";
import ProfileBlocks from "@hellodarwin/core/lib/features/enums/profile-blocks";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Select from "antd/es/select";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  createPortfolio,
  deleteBanner,
  deleteLogo,
  deletePortfolio,
  deleteVideos,
  editTeamMember,
  fetchPortfolios,
  fetchProfileScore,
  fetchProviderById,
  fetchTeamMembers,
  removeTeamMember,
  selectProfileModal,
  selectProfileScore,
  selectProviderPortfolioById,
  selectProviderPortfolios,
  selectProviderTeamMembers,
  selectProvidersLoading,
  submitTeamMember,
  toggleProfileModal,
  updateBanner,
  updateCompany,
  updateLogo,
  updatePortfolio,
  updateVideos,
} from "../../features/api/slices/providers-slice";
import { selectCompanyContacts } from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type ProfilePreviewProps = {
  provider: Provider;
  tags: AdminSelectedTags;
};
const ProfilePreview = ({ provider, tags }: ProfilePreviewProps) => {
  const [state, setState] = useState<"edit" | "client" | "website">("edit");
  const { t } = useTranslation();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const contacts = useAppSelector((state) =>
    selectCompanyContacts(state, provider.company_id)
  );

  const teamMembers = useAppSelector(selectProviderTeamMembers);
  const portfolios = useAppSelector(selectProviderPortfolios);
  const profileScore = useAppSelector(selectProfileScore);

  useEffect(() => {
    if (!!provider.provider_id) {
      dispatch(fetchTeamMembers({ api, provider_id: provider.provider_id }));
      dispatch(fetchPortfolios({ api, providerId: provider.provider_id }));
      dispatch(fetchProfileScore({ api, providerId: provider.provider_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  //MODAL VARIABLES
  const modal = useAppSelector(selectProfileModal);
  const handleCloseModal = () => {
    dispatch(
      toggleProfileModal({ open: false, targetId: undefined, type: undefined })
    );
  };
  const toggleEditModal = (props: ProfileModalProps) => {
    dispatch(toggleProfileModal(props));
  };

  const activePortfolio = useAppSelector((state) =>
    selectProviderPortfolioById(state, modal.targetId || "")
  );

  const isLoading = useAppSelector(selectProvidersLoading);

  const functions: ProfileEditModalFunctions = {
    isLoading: isLoading,
    fetchProfile: () =>
      dispatch(fetchProviderById({ api, providerId: provider.provider_id })),
    updateProfile: ({ data }) =>
      dispatch(
        updateCompany({ api, request: data, company_id: provider.company_id })
      ),
    submitPortfolio: ({ data }) =>
      dispatch(
        createPortfolio({ api, data, providerId: provider.provider_id })
      ),
    updatePortfolio: ({ data }) =>
      dispatch(
        updatePortfolio({ api, data, providerId: provider.provider_id })
      ),
    removePortfolio: ({ portfolioID }) =>
      dispatch(deletePortfolio({ api, portfolio_id: portfolioID })),
    updateBanner: ({ companyId, data }) =>
      dispatch(updateBanner({ api, data, companyId })),
    updateLogo: ({ companyId, data }) =>
      dispatch(updateLogo({ api, data, companyId })),
    updateVideos: ({ companyId, data }) =>
      dispatch(updateVideos({ api, data, companyId })),
    submitTeamMember: ({ data }) =>
      dispatch(
        submitTeamMember({ api, data, providerId: provider.provider_id })
      ),
    editTeamMember: ({ data, teamMemberId }) =>
      dispatch(
        editTeamMember({
          api,
          data,
          teamMemberId,
          providerId: provider.provider_id,
        })
      ),
    removeTeamMember: ({ teamMemberID }) =>
      dispatch(
        removeTeamMember({
          api,
          teamMemberID,
          providerId: provider.provider_id,
        })
      ),
    deleteBanner: ({ companyId }) => dispatch(deleteBanner({ api, companyId })),
    deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
    deleteVideos: ({ companyId }) => dispatch(deleteVideos({ api, companyId })),
  };

  if (!provider.name) return <Loading />;

  return (
    <>
      <Div flex="column" align="center" justify="center">
        <Div fitContent flex="column" gap={48}>
          <Div flex="row">
            <Div flex="column">
              {" "}
              <Typography>
                {"Profile Completion Score: "}
                {profileScore.analytics_profile_completed_percentage}
              </Typography>
            </Div>
            <Div flex="column" align="flex-end">
              <Select
                options={[
                  {
                    label: parse(t("provider-profile|viewTypes.edit")),
                    value: "edit",
                  },
                  {
                    label: parse(t("provider-profile|viewTypes.client")),
                    value: "client",
                  },
                  {
                    label: parse(t("provider-profile|viewTypes.website")),
                    value: "website",
                  },
                ]}
                size="large"
                style={{ width: 250 }}
                onChange={setState}
                value={state}
              />
            </Div>
          </Div>
          <ProviderProfile
            provider={provider}
            state={state}
            portfolios={portfolios}
            teamMembers={teamMembers}
            reviews={provider.reviews ?? []}
            toggleEditModal={toggleEditModal}
            sections={
              state === "edit"
                ? [
                    ProfileBlocks.FastInfos,
                    ProfileBlocks.About,
                    ProfileBlocks.Presentation,
                    ProfileBlocks.Portfolios,
                    ProfileBlocks.Reviews,
                    ProfileBlocks.Team,
                    ProfileBlocks.Expertise,
                    ProfileBlocks.Contact,
                  ]
                : state === "website"
                  ? [
                      ProfileBlocks.FastInfos,
                      ProfileBlocks.About,
                      ProfileBlocks.Presentation,
                      ProfileBlocks.Portfolios,
                      ProfileBlocks.Expertise,
                      ProfileBlocks.Reviews,
                      ProfileBlocks.Team,
                      ProfileBlocks.Contact,
                    ]
                  : [
                      ProfileBlocks.FastInfos,
                      ProfileBlocks.Presentation,
                      ProfileBlocks.Portfolios,
                      ProfileBlocks.About,
                      ProfileBlocks.Reviews,
                      ProfileBlocks.Team,
                      ProfileBlocks.Expertise,
                      ProfileBlocks.Contact,
                    ]
            }
          />
        </Div>

        <ProfileEditModal
          profile={provider}
          modal={modal}
          handleCancel={handleCloseModal}
          functions={functions}
          tags={tags}
          teamMembers={teamMembers}
          activePortfolio={activePortfolio}
        />

        {contacts?.[0] && (
          <ContactPreview
            isVisible={showModal}
            onCancel={() => setShowModal(false)}
            logo={provider.logo || ""}
            name={provider.name || ""}
            title={contacts?.[0].title || ""}
            phone={contacts?.[0].phone || ""}
            ext={contacts?.[0].ext || ""}
            email={contacts?.[0].email || ""}
          />
        )}
      </Div>
    </>
  );
};

export default ProfilePreview;

