import Button from "@hellodarwin/core/lib/components/common/button";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import { AdminGrantResult } from "@hellodarwin/core/lib/features/entities";
import message from "antd/es/message";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import { useAppDispatch } from "../../app/app-hooks";
import CreateGrantForm from "../../components/grants/grant-form/create-grant-form";
import {
  createGrant,
  fetchSimilarGrants,
} from "../../features/api/slices/grants-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { AdminPagesForms } from "../single-project-page";

const CreateGrantPage = () => {
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [grant, setGrant] = useState<AdminGrantResult>({
    grant_id: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOnGrantInfo, setIsOnGrantInfo] = useState<boolean>(false);

  const [forms, setForms] = useState<AdminPagesForms>({
    grantForm: undefined,
  });

  const renderActionButton = () => {
    if (isOnGrantInfo) {
      return (
        <Button onClick={onSave} size="small">
          Create a Grant
        </Button>
      );
    }
    return <></>;
  };

  const onSave = async () => {
    try {
      setIsLoading(true);
      const grantFormValues = forms.grantForm?.getFieldsValue()!;

      if (grantFormValues === undefined) {
        return;
      }
      if (
        !grantFormValues.grant_title_en ||
        !grantFormValues.grant_slug_en ||
        !grantFormValues.grant_display_title_en ||
        grantFormValues.grant_urls_en[0] === ""
      ) {
        message.error("Please fill in all required fields in English");
        setIsLoading(false);
        return;
      }

      if (
        !grantFormValues.grant_title_fr ||
        !grantFormValues.grant_slug_fr ||
        !grantFormValues.grant_display_title_fr ||
        grantFormValues.grant_urls_fr[0] === ""
      ) {
        message.error("Please fill in all required fields in French");
        setIsLoading(false);
        return;
      }

      const updatedGrant: AdminGrantResult = {
        ...grant,
        ...grantFormValues,
      };

      updatedGrant.grant_slug_en = slugify(updatedGrant.grant_slug_en);
      updatedGrant.grant_slug_fr = slugify(updatedGrant.grant_slug_fr);

      if (
        updatedGrant.grant_description_short_en === undefined ||
        updatedGrant.grant_description_short_fr === undefined ||
        updatedGrant.grant_description_long_en === undefined ||
        updatedGrant.grant_description_long_fr === undefined
      ) {
        updatedGrant.verified = "Unverified";
      }

      const newGrant = await dispatch(
        createGrant({ api, grant: updatedGrant })
      ).unwrap();

      setIsLoading(false);
      if (newGrant) {
        const similarGrantIDs = await dispatch(
          fetchSimilarGrants({ api, grantID: newGrant.grant_id })
        ).unwrap();
        if (similarGrantIDs.length !== 0) {
          navigate("/grants/create/similar/" + newGrant.grant_id);
          return;
        }

        message.success("Grant created with success !");
        setGrant({ grant_id: "" });
        forms.grantForm?.resetFields();
        navigate("/grants/" + newGrant.grant_id);
      }
    } catch (e: any) {
      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const goToGrants = () => {
    navigate("/grants");
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={"Create a Grant"}
      title={"Create a Grant"}
      handleBack={goToGrants}
      actions={renderActionButton()}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants",
          path: "/grants",
        },
        {
          breadcrumbName: "Create a Grant",
        },
      ]}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <CreateGrantForm
            grant={grant}
            setForms={setForms}
            setIsOnGrantInfo={setIsOnGrantInfo}
          ></CreateGrantForm>
        </>
      )}
    </PageLayout>
  );
};

export default CreateGrantPage;

