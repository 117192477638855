import { Program } from "@hellodarwin/core/lib/features/entities";
import Modal from "antd/es/modal/Modal";
import { message } from "antd/lib";
import { useState } from "react";
import { useAppDispatch } from "../../app";
import ProgramsSearch from "../../components/programs/programs-search";
import { updateProgram } from "../../features/api/slices/programs-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

type ChangeProgramDealModalProps = {
  open: boolean;
  onClose: () => void;
  program: Program;
};

export const ChangeProgramDealModal = ({
  open,
  onClose,
  program,
}: ChangeProgramDealModalProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const [selectedDeal, setSelectedDeal] = useState<string | undefined>(
    program.program_hs_deal_id
  );

  const handleSelect = (value: string) => {
    setSelectedDeal(value);
  };

  const handleApply = async () => {
    try {
      if (selectedDeal === program.program_hs_deal_id) {
        message.error("Please select a different deal");
        return;
      }
      await dispatch(
        updateProgram({
          api,
          updatedProgram: {
            ...program,
            ...{ program_hs_deal_id: selectedDeal },
          },
        })
      );
      message.success("Program deal updated");
      onClose();
    } catch (error) {
      message.error("Error updating program deal");
      console.error(error);
    }
  };

  return (
    <Modal
      title="Change Program Deal"
      open={open}
      onOk={handleApply}
      onCancel={onClose}
    >
      <ProgramsSearch
        companyId={program.program_company_id}
        handleSelect={handleSelect}
      />
    </Modal>
  );
};

