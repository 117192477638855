import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import Button from "antd/es/button";
import Flex from "antd/es/flex";
import Radio from "antd/es/radio";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app";
import {
  deleteGrant,
  fetchSimilarGrants,
} from "../../features/api/slices/grants-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import SingleGrantForm from "./single-grant-form";

const SimilarGrantPage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [grantIndex, setGrantIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const params = useParams<{
    id: string;
  }>();

  const [grantIDs, setGrantIDs] = useState<string[]>([""]);

  const goBack = async () => {
    await dispatch(deleteGrant({ api, grantID: grantIDs[0] }));
    navigate("/grants");
  };

  const goToGrant = () => {
    navigate(`/grants/${grantIDs[0]}`);
  };

  useEffect(() => {
    if (params.id) {
      fetchInitialData(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInitialData = async (grantID: string) => {
    const similarGrantIDs = await dispatch(
      fetchSimilarGrants({ api, grantID })
    ).unwrap();
    similarGrantIDs.unshift(grantID);
    setGrantIDs(similarGrantIDs);
    setIsLoading(false);
  };

  const displayRadioButtons = () => {
    return grantIDs.map((grantID, index) => {
      return (
        <Radio.Button key={index} value={index}>
          {index === 0 ? "Created grant" : `Grant ${index}`}
        </Radio.Button>
      );
    });
  };

  const changeGrantIndex = (value: any) => {
    setGrantIndex(parseInt(value.target.value));
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={"Create a Grant"}
      title={"Create a Grant"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Create a Grant",
        },
      ]}
      actions={
        <>
          {grantIDs[0] === "" || isLoading ? (
            <></>
          ) : (
            <div
              style={{
                minWidth: "70vw",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  maxWidth: "70%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 15,
                    color: theme.colors.purple_1,
                  }}
                  textAlign="center"
                >
                  We have found {grantIDs.length - 1} similar grants:{" "}
                </Typography>
                <Flex vertical gap="middle" style={{}}>
                  <Radio.Group
                    value={grantIndex}
                    onChange={changeGrantIndex}
                    buttonStyle="solid"
                  >
                    {displayRadioButtons()}
                  </Radio.Group>
                </Flex>
              </div>
              <div
                style={{
                  display: "flex",
                  maxWidth: "30%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  color={theme.colors.purple_1}
                  onClick={goBack}
                  style={{ marginRight: 15 }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  color={theme.colors.purple_1}
                  onClick={goToGrant}
                >
                  Still create
                </Button>
              </div>
            </div>
          )}
        </>
      }
    >
      {grantIDs[0] === "" || isLoading ? (
        <Loading></Loading>
      ) : (
        <SingleGrantForm grantID={grantIDs[grantIndex]}></SingleGrantForm>
      )}
    </PageLayout>
  );
};

export default SimilarGrantPage;

