import { FormViewerType } from ".";
import { TranslatedFormResponse } from "../../../features/entities/form-entities";
import Div from "../../common/div";
import Typography from "../../common/typography";

const FormHeader = ({
  formResponse,
  isEndPage,
  isOpening,
  type,
}: {
  formResponse: TranslatedFormResponse;
  isEndPage: boolean;
  isOpening: boolean;
  type: FormViewerType;
}) => {
  if (type === "modal" || isEndPage || isOpening) return <></>;
  return (
    <Div flex="column" gap={8} tablet={{ style: { padding: "0 24px" } }}>
      <Typography.Title level={1} elementTheme={"h4"} textAlign="center">
        {formResponse.title}
      </Typography.Title>
      <Typography elementTheme={"body1"} textAlign="center">
        {formResponse.subtitle}
      </Typography>
    </Div>
  );
};

export default FormHeader;

