import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import getItemTranslatedLabel from "@hellodarwin/core/lib/components/forms/form-builder/utils/get-item-translated-label";
import {
  FormSection,
  FormsSubmissionItem,
} from "@hellodarwin/core/lib/features/entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import FormCard from "../../components/forms/form-card";
import FormSubmissionCompanyCard from "../../components/forms/submissions/form-submission-company-card";
import FormSubmissionContactCard from "../../components/forms/submissions/form-submission-contact-card";
import FormSubmissionEditInput from "../../components/forms/submissions/form-submission-edit-input";
import {
  fetchSingleFormSubmission,
  selectActiveFormSubmission,
  updateFormSubmissionItem,
} from "../../features/api/slices/forms-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
const FormSubmissionSinglePage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedLocale } = useLocale();

  const { formSubmissionId } = useParams<{
    formSubmissionId: string;
  }>();

  const submission = useAppSelector(selectActiveFormSubmission);

  useEffect(() => {
    (async () => {
      if (!!formSubmissionId) {
        dispatch(fetchSingleFormSubmission({ api, formSubmissionId }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditItem = async (
    formSubmissionItem: FormsSubmissionItem,
    value: string
  ) => {
    try {
      const editedFormSubmissionItem: FormsSubmissionItem = {
        ...formSubmissionItem,
        value,
      };

      await dispatch(
        updateFormSubmissionItem({
          api,
          formSubmissionItem: editedFormSubmissionItem,
        })
      ).unwrap();
      message.success(
        `Updated ${getShortId(
          formSubmissionItem.form_submission_item_id
        )} with value ${value}`
      );
    } catch (error) {
      message.error(
        `Coudn't update ${getShortId(
          formSubmissionItem.form_submission_item_id
        )} with value ${value}`
      );
    }
  };

  const columns: ColumnsType<FormSection> = [
    {
      title: "Question",
      key: "content_en",
      render(value, record, index) {
        return (
          <Typography elementTheme="subtitle2">
            {getItemTranslatedLabel({
              label_en: record.form_item.content_en,
              label_fr: record.form_item.content_fr,
              locale: selectedLocale,
              fallback: record.form_item.form_item_id,
            })}
          </Typography>
        );
      },
    },
    {
      title: "Value",
      key: "value",
      width: 380,
      render(value, record, index) {
        return (
          <FormSubmissionEditInput
            formSection={record}
            handleSave={handleEditItem}
          />
        );
      },
    },
  ];

  if (!submission) return <></>;

  return (
    <PageLayout
      app="admin"
      title={submission?.form.title_en}
      handleBack={handleBack}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Forms",
          path: "/forms",
        },
        {
          breadcrumbName: "Submissions",
          path: "/forms/submissions",
        },
        {
          breadcrumbName: submission?.form.title_en,
        },
      ]}
    >
      <Div flex="row" gap={16} align="stretch">
        <FormCard form={submission.form} />

        <FormSubmissionContactCard contact={submission.contact} />

        <FormSubmissionCompanyCard company={submission.company} />
      </Div>

      <Table
        columns={columns}
        dataSource={submission.form_sections}
        pagination={{
          defaultPageSize: 20,
          position: ["bottomCenter"],
          showSizeChanger: true,
        }}
      />
    </PageLayout>
  );
};

export default FormSubmissionSinglePage;

