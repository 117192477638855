import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { HdChatTemplate } from "@hellodarwin/core/lib/features/entities";
import {
  getShortFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import Dropdown from "antd/es/dropdown/dropdown";
import { MenuProps } from "antd/es/menu/menu";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { selectSelectedChatType } from "../../features/api/slices/hd-chats-slice";
import {
  deleteTemplate,
  fetchAllTemplates,
  selectAllTemplates,
  selectTemplatesLoading,
  selectTemplatesPagination,
} from "../../features/api/slices/templates-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import CreateUpdateTemplateDrawer from "./create-update-template-drawer";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const TemplatesTab = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const chatType = useAppSelector(selectSelectedChatType)

  useEffect(() => {
    dispatch(
      fetchAllTemplates({ api, page: DEFAULT_PAGE, size: DEFAULT_LIMIT, type: chatType })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType]);

  const loading = useAppSelector(selectTemplatesLoading);
  const pagination = useAppSelector(selectTemplatesPagination);
  const [currentPage, setCurrentPage] = useState(pagination.page);
  const [pageSize, setPageSize] = useState(pagination.size);
  const templates = useAppSelector((state) => selectAllTemplates(state));
  const [addNewPromptDrawer, setAddNewPromptDrawer] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(
    {} as HdChatTemplate
  );

  const onPageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
    dispatch(fetchAllTemplates({ api, page, size, type: chatType }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (v) => getShortId(v),
      width: 110,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 110,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 110,
    },
    {
      title: "Grant ID",
      dataIndex: "grant_id",
      key: "grant_id",
      render: (v) => getShortId(v),
      width: 110,
    },
    {
      title: "Grant Title",
      dataIndex: "title",
      key: "title",
      width: 150,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (v) => getShortFormattedDate(v),
      width: 110,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (v) => getShortFormattedDate(v),
      width: 110,
    },
    {
      title: "Content FR",
      dataIndex: "content_fr",
      key: "content_fr",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Content EN",
      dataIndex: "content_en",
      key: "content_en",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      width: 60,
      align: "center",
      render: (_, template) => {
        const actionMenuItems: MenuProps["items"] = [
          {
            label: "Delete",
            style: { color: theme.colors.red_1 },
            key: "delete",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              dispatch(deleteTemplate({ api, templateId: template.id }));
            },
          },
        ];
        return (
          <Div
            flex="row"
            gap={20}
            fitContent
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown menu={{ items: actionMenuItems }}>
              <EllipsisOutlined />
            </Dropdown>
          </Div>
        );
      },
    },
  ];

  return (
    <Div flex="column" style={{ margin: 20, width: "auto" }} gap={24}>
      <Button
        defaultStyle={theme.colors.purple_1}
        size="small"
        onClick={() => {
          setSelectedTemplate({} as HdChatTemplate);
          setAddNewPromptDrawer(true);
        }}
      >
        Add new template
      </Button>
      <Table
        rowKey="id"
        dataSource={templates}
        columns={columns}
        scroll={{ y: "calc(100vh - 500px)" }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: pagination.total,
          onChange: onPageChange,
        }}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedTemplate(record);
              setAddNewPromptDrawer(true);
            },
          };
        }}
      />
      <CreateUpdateTemplateDrawer
        template={selectedTemplate}
        visible={addNewPromptDrawer}
        onClose={() => setAddNewPromptDrawer(false)}
      />
    </Div>
  );
};

export default TemplatesTab;

