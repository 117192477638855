import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Popconfirm from "antd/es/popconfirm";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import { FormPageActionsProps } from "../../types";
import FormPageEditButton from "./form-page-edit-button";

const FormPageActions = ({
  formPageId,
  functions,
  page,
  isEndPage,
  isOpeningPage,
}: FormPageActionsProps) => {
  const theme = useTheme();

  const handleDelete = () => {
    functions.deleteFormPage(formPageId);
  };

  return (
    <Div
      flex={"row"}
      gap={8}
      justify={isEndPage || isOpeningPage ? "flex-end" : undefined}
      fitContent={!(isEndPage || isOpeningPage)}
    >
      <FormPageEditButton page={page} functions={functions} />
      <Popconfirm
        title="Delete the form Page"
        description="Are you sure to delete this page ? You will lose all the groups items in it"
        onConfirm={handleDelete}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <Button
          size="circle"
          trailingIcon={<Delete width={20} height={20} />}
          defaultStyle={theme.colors.white_1}
        />
      </Popconfirm>
    </Div>
  );
};
export default FormPageActions;

