import Button from "@hellodarwin/core/lib/components/common/button";
import { Language } from "@hellodarwin/core/lib/features/entities/translations_ns";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import theme from "../theme";

export const SUPPORTED_LANGUAGES: Language[] = ["fr", "en"];

const LanguageSwitcher = () => {
  const { selectedLocale, changeLanguage } = useLocale();

  const otherLang = SUPPORTED_LANGUAGES.find((lang) => selectedLocale !== lang);

  const clickHandler = () => {
    if (otherLang) {
      changeLanguage(otherLang);
    }
  };

  return (
    <>
      <Button
        defaultStyle={theme.colors.grey_5}
        onClick={clickHandler}
        key={otherLang}
        style={{
          textTransform: "uppercase",
          width: 35,
          height: 35,
          minWidth: "auto",
          minHeight: "auto",
          borderRadius: 2,
        }}
      >
        {otherLang}
      </Button>
    </>
  );
};

export default LanguageSwitcher;

