import { IconProps } from "../features/entities/general";

const Refresh = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6943 10.8397H31L25.7074 5.24629C24.0035 3.44661 21.881 2.15242 19.5534 1.49381C17.2257 0.835206 14.775 0.835399 12.4474 1.49437C10.1198 2.15334 7.99755 3.44787 6.29388 5.24782C4.59021 7.04776 3.3652 9.28969 2.742 11.7482M1.00166 28.9326V20.1603M1.00166 20.1603H9.30736M1.00166 20.1603L6.29255 25.7537C7.99648 27.5534 10.119 28.8476 12.4466 29.5062C14.7742 30.1648 17.225 30.1646 19.5526 29.5056C21.8802 28.8467 24.0024 27.5521 25.7061 25.7522C27.4098 23.9522 28.6348 21.7103 29.258 19.2518M31 2.06738V10.8362"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Refresh;
