import { IconProps } from "../features/entities/general";

const ImagePlus = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.51271 3.98633C6.61069 3.98633 4.25781 6.37432 4.25781 9.31965V21.3196V22.653C4.25781 25.5983 6.61069 27.9863 9.51271 27.9863H22.6499C25.552 27.9863 27.9048 25.5983 27.9048 22.653V21.3196V9.31965C27.9048 6.37432 25.552 3.98633 22.6499 3.98633H9.51271ZM9.51271 6.65299H22.6499C24.1016 6.65299 25.2774 7.84632 25.2774 9.31965L25.2826 17.581C24.2606 16.6183 23.2188 16.0156 22.0745 15.9863C22.0338 15.985 22.0351 15.9863 21.9931 15.9863C20.4994 15.9863 18.8388 17.1516 17.7235 18.6116C17.4384 17.9583 17.1507 17.313 16.8197 16.6943C15.2695 13.789 13.5761 11.9903 11.4833 11.9863C9.70451 11.9823 8.17797 13.4583 6.87476 15.4796L6.88526 9.31965C6.88526 7.84632 8.06104 6.65299 9.51271 6.65299ZM21.3362 9.31965C20.611 9.31965 20.0225 9.91699 20.0225 10.653C20.0225 11.389 20.611 11.9863 21.3362 11.9863C22.0614 11.9863 22.6499 11.389 22.6499 10.653C22.6499 9.91699 22.0614 9.31965 21.3362 9.31965ZM11.4833 14.653C12.2479 14.6543 13.4092 15.8623 14.5206 17.945C14.962 18.769 15.3575 19.677 15.7122 20.5703C15.925 21.1036 16.0892 21.5117 16.1628 21.7357C16.5214 22.825 17.977 22.9796 18.5446 21.9863C18.601 21.8876 18.7035 21.6983 18.873 21.445C19.1568 21.0183 19.4799 20.5863 19.8175 20.1943C20.661 19.213 21.4742 18.653 21.9931 18.653C22.5173 18.6663 23.3265 19.225 24.1686 20.1943C24.5102 20.5876 24.8255 21.0183 25.1132 21.445C25.2038 21.5783 25.217 21.641 25.2774 21.7357V22.653C25.2774 24.1263 24.1016 25.3196 22.6499 25.3196H9.51271C8.06104 25.3196 6.88526 24.1263 6.88526 22.653V21.5276C6.96802 21.2903 7.07838 21.009 7.25442 20.5703C7.61307 19.677 8.00191 18.7703 8.44595 17.945C9.56655 15.8583 10.72 14.6516 11.4833 14.653Z"
          fill="currentColor"
        />
        <path
          d="M26.9358 21.3027C23.3081 21.3027 20.3672 24.2874 20.3672 27.9694C20.3672 31.6514 23.3081 34.6361 26.9358 34.6361C30.5635 34.6361 33.5044 31.6514 33.5044 27.9694C33.5044 24.2874 30.5635 21.3027 26.9358 21.3027ZM26.9358 24.6361C27.2986 24.6361 27.5927 24.9347 27.5927 25.3027V27.3027H29.5633C29.9261 27.3027 30.2201 27.6014 30.2201 27.9694C30.2201 28.3374 29.9261 28.6361 29.5633 28.6361H27.5927V30.6361C27.5927 31.0041 27.2986 31.3027 26.9358 31.3027C26.573 31.3027 26.279 31.0041 26.279 30.6361V28.6361H24.3084C23.9456 28.6361 23.6515 28.3374 23.6515 27.9694C23.6515 27.6014 23.9456 27.3027 24.3084 27.3027H26.279V25.3027C26.279 24.9347 26.573 24.6361 26.9358 24.6361Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default ImagePlus;
