import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  fetchGrantApplication,
  selectGrantApplicationById,
  selectGrantApplicationIsLoading,
  updateGrantApplication,
} from "../../features/api/slices/grants-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";

const SingleGrantApplicationPage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const grantApplication = useAppSelector((state) =>
    selectGrantApplicationById(state, id || "")
  );
  const loading = useAppSelector(selectGrantApplicationIsLoading);
  const [grantApplicationContent, setGrantApplicationContent] =
    useState<string>(grantApplication?.content || "");

  useEffect(() => {
    if (id) {
      dispatch(fetchGrantApplication({ api, id }));
    }
  }, [id, dispatch, api]);

  useEffect(() => {
    if (grantApplication) {
      setGrantApplicationContent(grantApplication.content);
    }
  }, [grantApplication]);

  const handleSave = () => {
    dispatch(
      updateGrantApplication({
        api,
        id: grantApplication?.id || "",
        content: grantApplicationContent,
      })
    );
  };

  return (
    <PageLayout
      app="admin"
      title={
        <Div flex="row" gap={32} align="center">
          <Typography.Title level={3} noMargin>
            Grant Application
          </Typography.Title>
          <Link
            target="_blank"
            to={`/chats/${grantApplication?.chat_id}`}
            style={{
              color: theme.colors.purple_1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            Chat
            <NewWindow style={{ color: theme.colors.purple_1 }} width={16} />
          </Link>
          <Link
            target="_blank"
            to={`/grants/${grantApplication?.grant_id}`}
            style={{
              color: theme.colors.purple_1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            Grant
            <NewWindow style={{ color: theme.colors.purple_1 }} width={16} />
          </Link>
        </Div>
      }
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants Applications",
          path: "/grants/applications",
        },
        {
          breadcrumbName: "Grant Application",
        },
      ]}
      actions={
        <Button
          size="small"
          onClick={handleSave}
          loading={loading}
          defaultStyle={theme.colors.purple_1}
        >
          Save
        </Button>
      }
    >
      <ReactQuill
        theme="snow"
        value={grantApplicationContent || ""}
        onChange={(content) => setGrantApplicationContent(content)}
      />
    </PageLayout>
  );
};

export default SingleGrantApplicationPage;

