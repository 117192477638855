import DesktopNavigation from "@hellodarwin/core/lib/components/layout/navigation/desktop-navigation";
import MobileNavigation from "@hellodarwin/core/lib/components/layout/navigation/mobile-navigation";
import { NavSider } from "@hellodarwin/core/lib/components/layout/navigation/styles";
import { NavigationLink } from "@hellodarwin/core/lib/features/entities";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import { useAppData } from "@hellodarwin/core/lib/features/providers/app-provider";
import { useLogin } from "@hellodarwin/core/lib/features/providers/login-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import ArrowOutline from "@hellodarwin/icons/dist/icons/ArrowOutline";
import Chat from "@hellodarwin/icons/dist/icons/Chat";
import Enterprise from "@hellodarwin/icons/dist/icons/Enterprise";
import Grants from "@hellodarwin/icons/dist/icons/Grants";
import Invoices from "@hellodarwin/icons/dist/icons/Invoices";
import Launch from "@hellodarwin/icons/dist/icons/Launch";
import LineViewOutline from "@hellodarwin/icons/dist/icons/LineViewOutline";
import ProjectsList from "@hellodarwin/icons/dist/icons/ProjectsList";
import RatingOutline from "@hellodarwin/icons/dist/icons/RatingOutline";
import Search from "@hellodarwin/icons/dist/icons/Search";
import User from "@hellodarwin/icons/dist/icons/User";
import { useLocation, useNavigate } from "react-router-dom";

const Navigation = () => {
  const theme = useTheme() as DefaultTheme;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const { handleLogout } = useLogin();
  const { navCollapsed, handleNavCollapse } = useAppData();

  const links: NavigationLink[] = [
    {
      pathname: "/projects",
      Icon: ProjectsList,
      label: "Projects",
    },
    {
      pathname: "/providers",
      Icon: Enterprise,
      label: "Providers",
    },
    {
      pathname: "/companies",
      Icon: User,
      label: "Companies",
    },
    {
      pathname: "/reviews",
      Icon: RatingOutline,
      label: "Reviews",
    },
    {
      pathname: "/grants",
      Icon: Grants,
      label: "Grants",
    },
    {
      pathname: "/search-grants",
      Icon: Search,
      label: "Search Grants",
    },
    {
      pathname: "/funding-explorer",
      Icon: Invoices,
      label: "Funding Explorer",
    },
    {
      pathname: "/forms",
      Icon: LineViewOutline,
      label: "Forms",
    },
    {
      pathname: "/chats",
      Icon: Chat,
      label: "Chats",
    },
    {
      pathname: "/programs",
      Icon: Launch,
      label: "Programs",
    },
  ];

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return isTablet ? (
    <MobileNavigation
      links={links}
      handleLogout={handleLogout}
      navigate={navigate}
      pathname={pathname}
      app="admin"
    />
  ) : (
    <NavSider
      className="ant-layout-sider-light"
      collapsible
      collapsed={navCollapsed}
      onCollapse={handleNavCollapse}
      width={theme.appearance.sidebarWidth}
      collapsedWidth={theme.appearance.sidebarWidthCollapsed}
      style={{ top: 0, position: "sticky", height: "100vh" }}
      theme={"light"}
      trigger={
        <ArrowOutline
          width={30}
          height={30}
          style={{
            width: 30,
            height: 30,
            transform: navCollapsed ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 500ms linear",
          }}
        />
      }
    >
      <DesktopNavigation
        links={links}
        app="admin"
        navigate={navigate}
        pathname={pathname}
      />
    </NavSider>
  );
};

export default Navigation;

