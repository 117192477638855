import Card from "antd/es/card";
import { Col, Row } from "antd/es/grid";
import Skeleton from "antd/es/skeleton";
import { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../app";
import { fetchMilestonesAttachments } from "../../features/api/slices/milestones-attachments-slice";
import {
  fetchMilestones,
  selectMilestonesByProgramId,
  selectMilestonesLoading,
} from "../../features/api/slices/milestones-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import MilestoneCard from "./milestone-card";

type MilestonesBlockProps = {
  programId: string;
};

const MilestonesBlock = ({ programId }: MilestonesBlockProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const milestones = useAppSelector((state: RootState) =>
    selectMilestonesByProgramId(state, programId)
  );

  const milestonesLoading = useAppSelector(selectMilestonesLoading);

  useEffect(() => {
    dispatch(fetchMilestones({ api, programId: programId }));
    dispatch(fetchMilestonesAttachments({ api, programId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  return (
    <div>
      <Row>
        {milestonesLoading ? (
          <Card style={{ width: "100%" }} title="Loading">
            <Skeleton></Skeleton>
          </Card>
        ) : milestones.length > 0 ? (
          milestones.map((m, i) => (
            <Col span={24} key={i} style={{ marginBottom: "10px" }}>
              <MilestoneCard milestone={m} index={i}></MilestoneCard>
            </Col>
          ))
        ) : (
          <Card
            style={{ width: "100%", paddingLeft: "1rem" }}
            title="No milestones for this program"
          />
        )}
      </Row>
    </div>
  );
};

export default MilestonesBlock;

